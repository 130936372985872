import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getGQLEnv } from '@app/env';
import GQL_ENDPOINT from '@app/configuration/graphql/graphql.default.config';

function getGraphQLEndpoint(): string {
  if (process.env.NODE_ENV === 'production') {
    return GQL_ENDPOINT;
  }

  const PRODUCTION_ENDPOINT = 'https://api.mealforward.org/graphql/';
  const STAGING_ENDPOINT = 'https://api.stg.mealforward.org/graphql/';
  const DEVELOPMENT_ENDPOINT = 'https://api.dev.mealforward.org/graphql/';
  const LOCAL_ENDPOINT = 'http://localhost:8000/graphql/';

  if (getGQLEnv() === 'production') {
    return PRODUCTION_ENDPOINT;
  }

  if (getGQLEnv() === 'staging') {
    return STAGING_ENDPOINT;
  }

  if (getGQLEnv() === 'development') {
    return DEVELOPMENT_ENDPOINT;
  }

  return LOCAL_ENDPOINT;
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    /**
     * CharityStatsPayload is not a keyed object which Apollo is unable
     * to safely merge, therefore this type policy lets Apollo know
     * how to merge data fetched from any CharityStatsPayload object.
     */
    CharityStatsPayload: {
      merge(existing, incoming) {
        return { ...existing, ...incoming };
      },
    },
  },
});
const link = createHttpLink({
  uri: getGraphQLEndpoint(),
});

export const client = new ApolloClient({
  cache,
  link: authLink.concat(link),
  name: 'react-web-client',
  version: '1.3',
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
