import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText';
import styles from './TableHeader.module.scss';

export interface TableHeaderProps {
  totalAndStateToggle: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  totalAndStateToggle,
}: TableHeaderProps) => {
  const header = totalAndStateToggle
    ? ['Date', 'Beneficiary', 'Meals', 'Price']
    : ['State', 'Date', 'Beneficiary', 'Meals', 'Price'];
  return (
    <TableHead>
      <TableRow>
        {header.map((cell) => {
          return (
            <TableCell
              key={header.indexOf(cell)}
              className={`${styles['styled-title-table-cell']} ${styles['table-header']}`}
            >
              <HeaderText size={HeaderSize.Small}>{cell}</HeaderText>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
