import React from 'react';
import Logo from '@app/components/core/Logo';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText';
import { BodyText, BodySize } from '@app/components/core/Typography/BodyText';
import styles from './mealRequestDesc.module.scss';

export const RequestMealDesc: React.FC = () => {
  return (
    <div className={styles['meal-request-desc-wrapper']}>
      <Logo isWithUnderBar />
      <HeaderText size={HeaderSize.Default}>
        Request meals on Meal Forward
      </HeaderText>
      <br />
      <BodyText size={BodySize.Default}>
        Request meal support for your organization and we’ll help find donors
        and restaurants who can provide you with nutritious meals.
      </BodyText>
    </div>
  );
};

export default RequestMealDesc;
