import { gql } from '@apollo/client';

export default gql`
  mutation MealRequestClaimRequestMutation(
    $restaurantID: Int!
    $requestID: Int!
    $taxPercentage: Float!
    $termsAcknowledgement: Boolean!
    $total: Float!
    $unitPriceMeat: Float!
    $unitPriceVegetarian: Float!
  ) {
    claim_request(
      request_id: $requestID
      restaurant_id: $restaurantID
      tax_percentage: $taxPercentage
      terms_acknowledgement: $termsAcknowledgement
      total: $total
      unit_price_meat: $unitPriceMeat
      unit_price_vegetarian: $unitPriceVegetarian
    ) {
      claim {
        current_state {
          state
        }
      }
    }
  }
`;
