import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';

import InvoiceFormErrors from '../InvoiceFormErrors';
import InvoiceFormRow, { InvoiceFormPriceRow } from '../InvoiceFormRow';

import styles from './InvoiceForm.module.scss';

export interface InvoiceFormProps {
  initialValues: InvoiceFormData;
  disabled?: boolean;
  footer?: React.ReactElement;
  onValidate?: (valid: boolean) => void;
  onSubmit?: (data: InvoiceFormData) => void;
}

export enum InvoiceFormKeys {
  MEAT_MEALS_COUNT = 'meat_meals_count',
  MEAT_MEALS_COST = 'meat_meals_cost',
  VEGETARIAN_MEALS_COUNT = 'vegetarian_meals_count',
  VEGETARIAN_MEALS_COST = 'vegetarian_meals_cost',
  TAX_RATE = 'tax_rate',
}

export interface InvoiceFormData {
  [InvoiceFormKeys.MEAT_MEALS_COUNT]: number;
  [InvoiceFormKeys.MEAT_MEALS_COST]: number;
  [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: number;
  [InvoiceFormKeys.VEGETARIAN_MEALS_COST]: number;
  [InvoiceFormKeys.TAX_RATE]: number;
}

const positiveValueTransform = (value: number) =>
  Number(Math.abs(value).toFixed(2));

function getValueTransform<T extends InvoiceFormKeys>(
  key: T,
): (value: InvoiceFormData[T]) => InvoiceFormData[T] {
  switch (key) {
    case InvoiceFormKeys.MEAT_MEALS_COST:
    case InvoiceFormKeys.VEGETARIAN_MEALS_COST:
      return positiveValueTransform;
    default:
      return (value) => value;
  }
}

function transform<T extends InvoiceFormKeys>(
  key: T,
  value: InvoiceFormData[T],
): InvoiceFormData[T] {
  return getValueTransform(key)(value);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function emptyFn(): void {}

/**
 * InvoiceForm displays a form for a restaurant to input
 * their prices and fees. The form will also calculate the overall
 * price of the invoice.
 */
export const InvoiceForm: React.FC<InvoiceFormProps> = ({
  initialValues,
  disabled,
  footer,
  onValidate,
  onSubmit,
}: InvoiceFormProps) => {
  const [formErrors, setFormErrors] = useState<Map<string, string>>(new Map());
  const getOrderSubtotal = useCallback((data: InvoiceFormData): number => {
    return (
      data[InvoiceFormKeys.MEAT_MEALS_COUNT] *
        data[InvoiceFormKeys.MEAT_MEALS_COST] +
      data[InvoiceFormKeys.VEGETARIAN_MEALS_COUNT] *
        data[InvoiceFormKeys.VEGETARIAN_MEALS_COST]
    );
  }, []);

  const validateForm = useCallback(
    (data) => {
      const errors: Map<InvoiceFormKeys, string> = new Map();
      const nonZeroCostErrorString = 'Cost must be greater than 0.';
      const nonZeroValues: Map<InvoiceFormKeys, boolean> = new Map();
      nonZeroValues.set(
        InvoiceFormKeys.VEGETARIAN_MEALS_COST,
        data[InvoiceFormKeys.VEGETARIAN_MEALS_COUNT] === 0 ||
          data[InvoiceFormKeys.VEGETARIAN_MEALS_COST] > 0,
      );
      nonZeroValues.set(
        InvoiceFormKeys.MEAT_MEALS_COST,
        data[InvoiceFormKeys.MEAT_MEALS_COUNT] === 0 ||
          data[InvoiceFormKeys.MEAT_MEALS_COST] > 0,
      );

      nonZeroValues.forEach((predicate, key) => {
        if (predicate) {
          return;
        }
        errors.set(key, nonZeroCostErrorString);
      });

      if (onValidate != null) onValidate(errors.size === 0);
      setFormErrors(errors);
      return Object.fromEntries(errors);
    },
    [onValidate],
  );

  const updateTaxRate = useCallback((handleChange) => {
    // eslint-disable-next-line no-alert
    const value = prompt(
      `What is your jurisdiction's sales tax rate? (in percentage form, input 8 for 8%)`,
    );
    const sanitized = Number(value);
    if (Number.isNaN(sanitized)) {
      return;
    }
    handleChange(sanitized / 100);
  }, []);

  const renderForm = useCallback(
    (form) => {
      // Don't render if the form object is not initialized yet
      if (!form || !form.values) return null;

      return (
        <Form>
          <div className={styles.form}>
            {form.values[InvoiceFormKeys.MEAT_MEALS_COUNT] !== 0 ? (
              <InvoiceFormRow
                name={InvoiceFormKeys.MEAT_MEALS_COST}
                value={form.values[InvoiceFormKeys.MEAT_MEALS_COST]}
                numberOfMeals={form.values[InvoiceFormKeys.MEAT_MEALS_COUNT]}
                disabled={disabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldValue(
                    InvoiceFormKeys.MEAT_MEALS_COST,
                    transform(
                      InvoiceFormKeys.MEAT_MEALS_COST,
                      Number(e.target.value),
                    ),
                  );
                }}
              />
            ) : null}
            {form.values[InvoiceFormKeys.VEGETARIAN_MEALS_COUNT] !== 0 ? (
              <InvoiceFormRow
                name={InvoiceFormKeys.VEGETARIAN_MEALS_COST}
                value={form.values[InvoiceFormKeys.VEGETARIAN_MEALS_COST]}
                numberOfMeals={
                  form.values[InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]
                }
                disabled={disabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldValue(
                    InvoiceFormKeys.VEGETARIAN_MEALS_COST,
                    transform(
                      InvoiceFormKeys.VEGETARIAN_MEALS_COST,
                      Number(e.target.value),
                    ),
                  );
                }}
              />
            ) : null}
            <InvoiceFormPriceRow
              name="Taxes"
              label={
                <span>
                  Taxes (
                  {disabled ? (
                    `${(form.values[InvoiceFormKeys.TAX_RATE] * 100).toFixed(
                      2,
                    )}%`
                  ) : (
                    <button
                      className={styles['tax-rate']}
                      type="button"
                      onClick={() =>
                        updateTaxRate((value: number) =>
                          form.setFieldValue(InvoiceFormKeys.TAX_RATE, value),
                        )
                      }
                    >
                      {(form.values[InvoiceFormKeys.TAX_RATE] * 100).toFixed(2)}
                      %
                    </button>
                  )}
                  )
                </span>
              }
              value={`${positiveValueTransform(
                getOrderSubtotal(form.values) *
                  form.values[InvoiceFormKeys.TAX_RATE],
              ).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: true,
              })}`}
            />
            <InvoiceFormPriceRow
              name="Total order price"
              value={`${positiveValueTransform(
                getOrderSubtotal(form.values) *
                  (1 + form.values[InvoiceFormKeys.TAX_RATE]),
              ).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: true,
              })}`}
              bolded
            />
          </div>
          <InvoiceFormErrors errors={formErrors} />
          {footer}
        </Form>
      );
    },
    [disabled, footer, formErrors, getOrderSubtotal, updateTaxRate],
  );

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit ?? emptyFn}
      validateOnMount
    >
      {renderForm}
    </Formik>
  );
};
InvoiceForm.defaultProps = {
  disabled: false,
  footer: undefined,
  onValidate: emptyFn,
  onSubmit: emptyFn,
};

export default InvoiceForm;
