/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

export type MFUser = { id: string; restaurantId: string };

const UserContext = React.createContext({
  user: null as MFUser | null,
  onLogout: () => {},
});

export default UserContext;
