import { gql } from '@apollo/client';

import ClaimDetailsFragment from '@app/views/restaurants/myOrders/graphql/fragments/ClaimDetailsFragment.graphql';

export default gql`
  ${ClaimDetailsFragment}
  query MyOrderDetailFormInfoQuery($claimID: Int!) {
    claim_details_by_claim_id(id: $claimID) {
      ...ClaimDetailsFragment
    }
  }
`;
