import React from 'react';
import { TextColor } from '../Typography';
import { BodySize, BodyText } from '../Typography/BodyText';

export interface AddressProps {
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  postalCode: string;
}

/**
 * Address formats address components into
 * the canonical representation typically found in the US.
 */
const Address: React.FC<AddressProps> = ({
  line1,
  line2,
  city,
  state,
  postalCode,
}: AddressProps) => {
  return (
    <div>
      <div>
        <BodyText color={TextColor.Light} size={BodySize.Small}>
          {line1}
        </BodyText>
      </div>
      {line2 == null ? null : (
        <div>
          <BodyText color={TextColor.Light} size={BodySize.Small}>
            {line2}
          </BodyText>
        </div>
      )}
      <div>
        <BodyText color={TextColor.Light} size={BodySize.Small}>
          {city}, {state} {postalCode}
        </BodyText>
      </div>
    </div>
  );
};

export default Address;
