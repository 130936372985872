import TermsOfUse from '@app/views/termsOfUse/TermsOfUse';
import PrivacyPolicy from '@app/views/privacyPolicy/PrivacyPolicy';
import OpenSource from '@app/views/openSource';
import LandingPage from '@app/views/landing/index';
import SignUp from '@app/views/signup/index';
import Login from '@app/views/login/index';
import RequestMeal from '@app/views/mealRequest/index';
import MyOrderPage from '@app/views/restaurants/myOrders/MyOrderPage';
import RestaurantDashboard from '@app/components/RestaurantDashboard';

import { AppPath } from '@app/paths';

export default function getAppPathComponent(path: AppPath): React.FC {
  switch (path) {
    case AppPath.INDEX:
      return LandingPage;
    case AppPath.MEAL_REQUEST:
      return RequestMeal;
    case AppPath.TERMS_OF_USE:
      return TermsOfUse;
    case AppPath.PRIVACY_POLICY:
      return PrivacyPolicy;
    case AppPath.OPEN_SOURCE:
      return OpenSource;
    case AppPath.SIGN_UP:
      return SignUp;
    case AppPath.LOGIN:
      return Login;
    case AppPath.RESTAURANT_HOME:
      return RestaurantDashboard;
    case AppPath.MY_ORDERS:
      return MyOrderPage;
    default: {
      const _: never = path;
      throw new Error('unreachable');
    }
  }
}
