import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import UserContext from '@app/UserContext';
import { AppPath, getAppPathConfig } from '@app/paths';
import { MFSeventyThirtyLayout } from '@app/components/core/Layout/MFSeventyThirtyLayout';
import Navigation from '@app/components/core/Navigation';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
  path: string;
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        user === null ? (
          <Redirect to={getAppPathConfig(AppPath.INDEX).uri} />
        ) : (
          <div>
            <Navigation navCurrent={path}>
              <MFSeventyThirtyLayout
                leftColumn={<Component {...props} />}
                rightColumn={<div />}
                showSeparator={false}
              />
            </Navigation>
          </div>
        )
      }
    />
  );
};

export default PrivateRoute;
