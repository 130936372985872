import { gql } from '@apollo/client';

/**
 * You can write GraphQL queries as simple as
 * you would write it in GraphiQL!
 *
 * Wrap your query in `gql` to denote GraphQL declarations.
 *
 * More info about GraphQL queries:
 *  https://graphql.org/learn/queries/
 *
 * Don't forget to codegen the types via `npm run gql-types`
 */

export default gql`
  mutation RegisterQuery(
    $addressData: AddressInput!
    $restaurantData: RestaurantInput!
    $contact: String!
    $email: String!
    $password: String!
  ) {
    create_restaurant(
      address_data: $addressData
      restaurant_data: $restaurantData
      email: $email
      contact: $contact
      password: $password
    ) {
      restaurant {
        name
        address {
          address_line_1
          city
          postal_code
        }
      }
    }
  }
`;
