import React from 'react';

import Grid from '@material-ui/core/Grid';
import { AppPath, getAppPathConfig } from '@app/paths';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button/index';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText/index';
import stackedPackages from '../images/stacked-packages.svg';

import styles from './Main.module.scss';

const buttonTheme = {
  color: ButtonColor.Secondary,
  size: ButtonSize.Large,
  width: ButtonWidth.Compact,
};

const Main: React.FC = () => {
  return (
    <>
      <Grid container item className={styles['main-wrapper']}>
        <Grid container item className={styles['main-container']}>
          <div className={styles['main-text']}>
            <HeaderText size={HeaderSize.Large}>
              {`Providing meal
               support to
               communities
               impacted by
               COVID-19`}
            </HeaderText>
          </div>
          <Grid container className={styles['donate-btn']}>
            <Button
              type="button"
              theme={buttonTheme}
              onClick={() => {
                window.open(
                  'https://give.cornerstone.cc/mealforward',
                  '_blank',
                );
              }}
            >
              Donate Now
            </Button>
          </Grid>
          <Grid container className={styles['sub-text']}>
            Are you a resturant owner?
            <a href={getAppPathConfig(AppPath.RESTAURANT_HOME).uri}>
              Claim an Order
            </a>
          </Grid>
          <Grid container className={styles['sub-text']}>
            Are you in need of meal support?
            <a href={getAppPathConfig(AppPath.MEAL_REQUEST).uri}>
              Request Meals
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={styles['main-image-wrapper']}
      >
        <Grid container item xs={10} sm={7}>
          <img src={stackedPackages} alt="stacked-packages" />
        </Grid>
      </Grid>
    </>
  );
};

export default Main;
