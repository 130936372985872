import React from 'react';

import styles from './FiftyFiftyLayout.module.scss';

export interface FiftyFiftyLayoutProps {
  leftColumn: React.ReactChild;
  rightColumn: React.ReactChild;
}

/**
 * FiftyFiftyLayout provides a simple 50-50 layout with no additional styling
 */
export const FiftyFiftyLayout: React.FC<FiftyFiftyLayoutProps> = ({
  leftColumn,
  rightColumn,
}: FiftyFiftyLayoutProps) => {
  return (
    <div className={styles.container}>
      <div className={styles['flex-item']}>{leftColumn}</div>
      <div className={styles['flex-item']}>{rightColumn}</div>
    </div>
  );
};

export default FiftyFiftyLayout;
