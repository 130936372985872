/* eslint react/prop-types: 0 */
import React from 'react';

import { AppPath, getAppPathConfig } from '@app/paths';

import UserContext from '@app/UserContext';
import styles from './Navigation.module.scss';
import NavItems from './NavItems';

interface NavProps {
  navCurrent?: string;
}

const Navigation: React.FC<NavProps> = ({ navCurrent, children }) => {
  const items = [
    {
      id: 1,
      title: 'Home',
      href: getAppPathConfig(AppPath.RESTAURANT_HOME).uri,
    },
    {
      id: 2,
      title: 'My Orders',
      href: getAppPathConfig(AppPath.MY_ORDERS).uri,
    },
  ];

  const { user, onLogout } = React.useContext(UserContext);

  if (user === null) return null;
  return (
    <>
      <nav className={`${styles['content-sb']} ${styles['position-right']}`}>
        <ul
          className={`${styles['display-flex']} ${styles.mt36} ${styles.mr66}`}
        >
          <li>
            {items.map((item) => (
              <NavItems
                key={item.id}
                title={item.title}
                href={item.href}
                current={navCurrent}
              />
            ))}
            <NavItems
              key={0}
              title="Logout"
              href="/"
              onNavItemClick={onLogout}
            />
          </li>
        </ul>
      </nav>
      {children}
    </>
  );
};
export default Navigation;
