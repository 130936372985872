import React from 'react';
import { HeaderText } from '@app/components/core/Typography/HeaderText';
import { BodyText } from '@app/components/core/Typography/BodyText';
import PublicPageLayout from '@app/components/core/Layout/PublicPageLayout';
import styles from './PrivacyPolicy.module.scss';

export default function PrivacyPolicy(): JSX.Element {
  return (
    <PublicPageLayout>
      <div className={styles['privacy-policy-container']}>
        <div className={styles.header}>
          <HeaderText>Privacy Policy</HeaderText>
        </div>
        <div className={styles.body}>
          <BodyText>
            Meal Forward Privacy Policy
            <br /> Updated June 14, 2020
          </BodyText>
          <BodyText>
            Maintaining your trust is our top priority. We want to be clear and
            straight-forward in how we respect your privacy. This Privacy Policy
            applies to products and services offered by Meal Forward Inc. (“Meal
            Forward”), and the use of the website www.mealsforward.org.
          </BodyText>
          <BodyText>
            WHAT INFORMATION WE COLLECT ON OUR WEBSITE.
            <br />
            Meal Forward may collect personally identifiable information, such
            as your name, email address, and phone number through our website.
            We may gather additional personal or non-personal information in the
            future. This information is used for the operations of the service,
            to maintain quality of the service, and to provide general
            statistics regarding use of the Meal Forward website.
          </BodyText>
          <BodyText>
            HOW WE USE YOUR PERSONAL INFORMATION.
            <br />
            Meal Forward collects and uses information you’ve provided to
            operate its business, and/or to deliver requested services. Meal
            Forward may also use your personally identifiable information to
            inform you of other products or services available from Meal Forward
            and its affiliates.
          </BodyText>
          <BodyText>
            Meal Forward does not sell, rent, or lease its collected personal
            information to third parties.
          </BodyText>
          <BodyText>
            Meal Forward may share data with partners to help perform necessary
            services requested by the users and customers (e.g., analysis, send
            email, provide customer support). All such partners are strictly
            prohibited from using your personal information except to provide
            these services to Meal Forward. Our partners are required to
            maintain the confidentiality of your information.
          </BodyText>
          <BodyText>
            Meal Forward may post a list and/or testimonials of users, donors,
            beneficiaries, and restaurants on the website that contains
            information such as names, titles, and entities. Meal Forward may
            identify users, donors, beneficiaries, and restaurants by name or
            its affiliations, with or without the use of the customer/client’s
            trademark, in general promotional materials without prior consent.
          </BodyText>
          <BodyText>
            Meal Forward may receive orders, subpoenas, or other legal demands
            for disclosing personal information that has been collected. In such
            cases, Meal Forward may disclose your personal or organization’s
            information without notice to comply with the legal process, protect
            and defend the rights or property of Meal Forward, and/or protect
            the physical safety of employees, volunteers, and partners of Meal
            Forward.
          </BodyText>
          <BodyText>
            SECURITY OF YOUR PERSONAL INFORMATION.
            <br />
            Meal Forward website secures your personal information from
            unauthorized access, use, or disclosure through encryption, such as
            Secure Sockets Layer (SSL) protocol.
          </BodyText>
          <BodyText>
            CHILDREN.
            <br />
            The Children’s Online Privacy Protection Act (“COPPA”) requires that
            online service providers obtain parental consent before they
            knowingly collect personally identifiable information online from
            children who are under 13. We do not knowingly collect or solicit
            personally identifiable information from children under 13; if you
            are a child under 13, please do not attempt to send any personal
            information about yourself to us. If we learn we have collected
            personal information from a child under 13, we will delete that
            information as quickly as possible. If you believe that a child
            under 13 may have provided us personal information, please contact
            us at legal@mealforward.org.
          </BodyText>
          <BodyText>
            CORRECTIONS TO INFORMATION.
            <br />
            You may contact us in or.bodyText der to correct, amend, or delete
            contact information you provided through the Meal Forward website
            that is inaccurate. You may request such changes by sending an email
            to legal@mealforward.org.
          </BodyText>
          <BodyText>
            CHANGES TO THIS PRIVACY POLICY.
            <br />
            Meal Forward may amend this Privacy Policy from time to time. Use of
            information we collect now is subject to the Privacy Policy in
            effect at the time such information is used. If we make any material
            changes to this Privacy Policy, we will provide notice of such
            changes through a posting on the Meal Forward website.
          </BodyText>
          <BodyText>
            QUESTIONS OR CONCERNS.
            <br />
            If you have any questions or concerns regarding privacy in
            connection with Meal Forward, please send us a detailed message at
            legal@mealforward.org. We will make efforts to resolve your
            concerns.
          </BodyText>
          <BodyText>Effective Date: June 14, 2020</BodyText>
        </div>
      </div>
    </PublicPageLayout>
  );
}
