import React from 'react';
import Logo from '@app/components/core/Logo';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText';
import { BodyText, BodySize } from '@app/components/core/Typography/BodyText';

import styles from './LoginDesc.module.scss';

export const LoginDesc: React.FC = () => {
  return (
    <div className={styles['login-desc-wrapper']}>
      <Logo isWithUnderBar />
      <HeaderText size={HeaderSize.Default}>Welcome back!</HeaderText>
      <br />
      <BodyText size={BodySize.Default}>
        Sign in to claim new orders or check your order history.
      </BodyText>
    </div>
  );
};

export default LoginDesc;
