import React from 'react';
import { Link } from 'react-router-dom';
import logoLight from '@app/views/landing/images/logo-light.svg';
import logoDark from '@app/views/landing/images/logo-dark.svg';
import { AppPath, getAppPathConfig } from '@app/paths';
import styles from './Logo.module.scss';

export enum LogoColor {
  Dark = 'dark',
  Light = 'light',
}
export interface LogoProps {
  isWithUnderBar?: boolean;
  color?: LogoColor.Dark | LogoColor.Light;
}

const Logo: React.FC<LogoProps> = ({ isWithUnderBar, color }: LogoProps) => {
  const logo = color === LogoColor.Dark ? logoDark : logoLight;

  return (
    <div className={styles['logo-container']}>
      <Link to={getAppPathConfig(AppPath.INDEX).uri}>
        <img src={logo} alt="logoImg" />
      </Link>
      {isWithUnderBar && <hr className={styles['hr-display']} />}
    </div>
  );
};

Logo.defaultProps = {
  isWithUnderBar: false,
  color: LogoColor.Dark,
};

export default Logo;
