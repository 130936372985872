import React, { useCallback } from 'react';
import { DismissButton } from '@app/components/core/Interaction/DismissButton';
import { TextColor } from '@app/components/core/Typography';
import {
  HeaderSize,
  HeaderText,
} from '@app/components/core/Typography/HeaderText';
import { SeventyThirtyLayout } from '@app/components/core/Layout/SeventyThirtyLayout';

import styles from './Sidebar.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SidebarProps {
  title?: string;
  body: React.ReactChild;
}

interface SidebarPropsInternal {
  onClose: () => void;
  onAnimationComplete: () => void;
  isInit: boolean;
  visible: boolean;
  outsideClickClosesSidebar: boolean;
}

type SidebarPropsWithInternal = SidebarProps & SidebarPropsInternal;

/**
 * Sidebar provides a visual element that slides in from the right that aims
 * to recontextualize the current activity the user is engaged in by focusing
 * into a different view.
 *
 * Do not use directly!
 *
 * To use this component, please use the `useSidebar` hook API
 */
export const Sidebar: React.FC<SidebarPropsWithInternal> = ({
  title = '',
  body,
  // Internal Properties set by useSidebar
  onClose,
  onAnimationComplete,
  isInit,
  visible,
  outsideClickClosesSidebar,
}: SidebarPropsWithInternal) => {
  const shoulderClassNames = [
    isInit
      ? styles['sidebar-shoulder-initial']
      : styles['sidebar-shoulder-final'],
    styles['sidebar-shoulder'],
  ];
  const panelClassNames = [
    isInit ? styles['sidebar-panel-initial'] : styles['sidebar-panel-final'],
    styles['sidebar-panel'],
  ];

  const handleOutsideClick = useCallback(() => {
    if (outsideClickClosesSidebar) {
      onClose();
    }
  }, [outsideClickClosesSidebar, onClose]);

  // Initiate hide animation css selectors
  if (!visible) {
    shoulderClassNames.push(styles['sidebar-shoulder-hide']);
    panelClassNames.push(styles['sidebar-panel-hide']);
  }

  return (
    <div className={styles.container}>
      <SeventyThirtyLayout
        hideLeftColumnOnNarrowViewport
        leftColumn={
          <div
            className={styles['sidebar-left-column']}
            onClick={handleOutsideClick}
            onKeyPress={handleOutsideClick}
            role="button"
            tabIndex={-1}
            aria-label={outsideClickClosesSidebar ? 'Tap to close sidebar' : ''}
          />
        }
        rightColumn={
          <div className={styles.sidebar}>
            <div
              className={shoulderClassNames.join(' ')}
              onAnimationEnd={onAnimationComplete}
              onClick={handleOutsideClick}
              onKeyPress={handleOutsideClick}
              role="button"
              tabIndex={-1}
              aria-label={
                outsideClickClosesSidebar ? 'Tap to close sidebar' : ''
              }
            />
            <div className={panelClassNames.join(' ')}>
              <div className={styles['sidebar-content-container']}>
                <div className={styles['sidebar-content']}>
                  <div className={styles.header}>
                    <div className={styles.title}>
                      <HeaderText
                        color={TextColor.Light}
                        size={HeaderSize.Default}
                      >
                        {title}
                      </HeaderText>
                    </div>
                    <div className={styles['dismiss-button']}>
                      <DismissButton
                        onPress={onClose}
                        ariaLabel="Close sidebar"
                      />
                    </div>
                  </div>
                  {body}
                </div>
              </div>
            </div>
          </div>
        }
        showSeparator={false}
      />
    </div>
  );
};

export default Sidebar;
