import React, { useState } from 'react';
import MFFiftyFiftyLayout from '@app/components/core/Layout/MFFiftyFiftyLayout/index';
import SignUpForm from './components/signUpForm/index';
import SignUpDesc from './components/signUpDesc/index';

export default function SignUp(): JSX.Element {
  const [barIdx, setBarIdx] = useState(0);

  return (
    <MFFiftyFiftyLayout
      leftColumn={<SignUpDesc barIdx={barIdx} />}
      rightColumn={<SignUpForm setBarIdx={setBarIdx} />}
    />
  );
}
