import React from 'react';

import { FiftyFiftyLayout } from '../FiftyFiftyLayout';

import styles from './MFFiftyFiftyLayout.module.scss';

export interface MFFiftyFiftyLayoutProps {
  leftColumn: React.ReactChild;
  rightColumn: React.ReactChild;
}

/**
 * MFFiftyFiftyLayout provides a simple 50-50 layout (slight offset)
 * with a colored panels and a background adornment;
 */
export const MFFiftyFiftyLayout: React.FC<MFFiftyFiftyLayoutProps> = ({
  leftColumn,
  rightColumn,
}: MFFiftyFiftyLayoutProps) => {
  return (
    <div className={styles.container}>
      <FiftyFiftyLayout
        leftColumn={
          <div className={styles['left-column']}>
            <div className={styles.content}>{leftColumn}</div>
            <div className={styles.adornment}>
              <div className={styles['adornment-position']}>
                <div />
                <div className={`${styles['adornment-offset']}`} />
              </div>
            </div>
          </div>
        }
        rightColumn={
          <div className={styles['right-column']}>{rightColumn}</div>
        }
      />
    </div>
  );
};

export default MFFiftyFiftyLayout;
