import React from 'react';
import { Grid } from '@material-ui/core';
import { HeaderText } from '@app/components/core/Typography/HeaderText';
import { BodySize, BodyText } from '@app/components/core/Typography/BodyText';
import PublicPageLayout from '@app/components/core/Layout/PublicPageLayout';
import styles from './index.module.scss';

export default function OpenSource(): JSX.Element {
  interface OpenSourceData {
    name: string;
    lan: string;
    url: string;
    desc: string;
  }
  const openSourceList: Array<OpenSourceData> = [
    {
      name: 'Apollo',
      lan: 'JavaScript',
      url: 'https://github.com/ApolloAuto/apollo',
      desc: 'A state management library to manage data with GraphQL.',
    },
    {
      name: 'Formik',
      lan: 'JavaScript',
      url: 'https://github.com/formium/formik',
      desc: 'A form library for React and React Native.',
    },
    {
      name: 'TypeScript',
      lan: 'JavaScript',
      url: 'https://github.com/microsoft/TypeScript',
      desc: 'Add optional types to JavaScript',
    },
    {
      name: 'Avalara',
      lan: 'Python',
      url: 'https://github.com/Avalara',
      desc: 'Python SDK for tax service',
    },
    {
      name: 'Uvicorn',
      lan: 'Python',
      url: 'https://github.com/encode/uvicorn',
      desc: 'An ASGI server implementation, using uvloop and httptools.',
    },
    {
      name: 'Pycognito',
      lan: 'Python',
      url: 'https://github.com/pvizeli/pycognito',
      desc: 'A Python library for using AWS Cognito',
    },
    {
      name: 'psycopg2',
      lan: 'Python',
      url: 'https://github.com/psycopg/psycopg2',
      desc: 'A PostgreSQL database adapter for Python',
    },
    {
      name: 'Python-dotenv',
      lan: 'Python',
      url: 'https://github.com/theskumar/python-dotenv',
      desc: 'Get and set values in .env file in local and production servers.',
    },
    {
      name: 'Pytest-django',
      lan: 'Python',
      url: 'https://github.com/pytest-dev/pytest-django',
      desc: 'A Django plugin for pytest.',
    },
    {
      name: 'Djangorestframework',
      lan: 'Python',
      url: 'https://github.com/encode/django-rest-framework',
      desc: 'A Web APIs for Django.',
    },
  ];

  return (
    <PublicPageLayout>
      <div className={styles['open-source-wrapper']}>
        <div className={styles['header-text']}>
          <HeaderText>Open Source</HeaderText>
        </div>
        <div className={styles['body-text']}>
          <BodyText>
            Meal Forward is an active participant in the open source community.
            <br />
            We’ve open sourced many of our own projects below, and we’re using
            open source software in a variety of ways across our products. We
            thank the open source community for all of their contributions!
          </BodyText>
        </div>
        <Grid className={styles['open-source-container']} container spacing={3}>
          {openSourceList.map((library) => (
            <Grid key={library.name} container item xs={12} sm={6} md={4}>
              <Grid
                container
                direction="column"
                className={styles['open-source-item-container']}
                alignItems="flex-start"
              >
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <BodyText size={BodySize.Default}> {library.name}</BodyText>
                  </Grid>
                  <Grid item className={styles.language}>
                    {library.lan}
                  </Grid>
                </Grid>
                <hr />
                <Grid item className={styles.desc}>
                  {library.desc}
                </Grid>
                <a target="_blank" rel="noreferrer" href={library.url}>
                  View on Github
                </a>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid className={styles.attribution} item>
          Referenced design from{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://opensource.dropbox.com/"
          >
            Dropbox
          </a>
        </Grid>
      </div>
    </PublicPageLayout>
  );
}
