import React from 'react';
import HeaderText, { HeaderSize } from 'components/core/Typography/HeaderText';

export interface TableTitleProps {
  title: string;
}

const TableTitle: React.FC<TableTitleProps> = ({ title }: TableTitleProps) => {
  return <HeaderText size={HeaderSize.Small}>{title}</HeaderText>;
};

export default TableTitle;
