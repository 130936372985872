import { useMutation, MutationTuple } from '@apollo/client';
import MarkDeliveredMutation from './graphql/mutations/MarkDeliveredMutation';
import {
  MarkDeliveredMutation as TMarkDeliveredMutation,
  MarkDeliveredMutationVariables,
} from './graphql/mutations/types/MarkDeliveredMutation';

export default function useMarkClaimDeliveredMutation(): MutationTuple<
  TMarkDeliveredMutation,
  MarkDeliveredMutationVariables
> {
  return useMutation(MarkDeliveredMutation);
}
