import React from 'react';
import RequestMealForm from '@app/views/mealRequest/components/mealRequestForm/index';
import RequestMealDesc from '@app/views/mealRequest/components/mealRequestDesc/index';
import MFFiftyFiftyLayout from '@app/components/core/Layout/MFFiftyFiftyLayout/index';

export default function RequestMeal(): JSX.Element {
  return (
    <MFFiftyFiftyLayout
      leftColumn={<RequestMealDesc />}
      rightColumn={<RequestMealForm />}
    />
  );
}
