import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import Routes from '@app/paths/Routes';
import UserContext, { MFUser } from './UserContext';
import REFRESH_QUERY from './graphql/examples/AuthRefreshQuery';
import { AuthRefreshQuery } from './graphql/examples/types/AuthRefreshQuery';
import ME_QUERY from './graphql/examples/MeQuery';
import { MeQuery } from './graphql/examples/types/MeQuery';

const AUTH_TOKEN_KEY = 'token';
const REFRESH_INTERVAL_MS = 1000 * 60 * 15; // Fifteen minutes

export function getAuthToken(): string | null {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

interface AppProps {
  onLogout: () => void;
}

const App: React.FC<AppProps> = ({ onLogout }: AppProps) => {
  /**
   * Initial authentication heartbeat
   */
  const [user, setUser] = useState<MFUser | null>(null);
  const [loading, setLoading] = useState(true);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    setUser(null);
    onLogout();
  }, [onLogout]);

  const meQueryCallback = useCallback(
    ({ me }) => {
      if (me == null || me.restaurant == null) {
        handleLogout();
        return;
      }

      setUser({ id: me.id, restaurantId: me.restaurant.id });
      setLoading(false);
    },
    [handleLogout],
  );

  const [authMe, { called }] = useLazyQuery<MeQuery>(ME_QUERY, {
    onCompleted: meQueryCallback,
    onError: () => {
      handleLogout();
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!called && !user) {
      setLoading(true);
      authMe();
    }
  }, [called, user, authMe]);

  /**
   * Refresh token cycle
   */

  const [authRefresh] = useMutation<AuthRefreshQuery>(REFRESH_QUERY, {
    onCompleted: ({ refresh_token: refreshToken }) => {
      const token = refreshToken?.token;
      if (token == null) return;
      localStorage.setItem('token', token);
    },
  });

  const [
    refreshTimeoutHandle,
    setRefreshTimeoutHandle,
  ] = useState<NodeJS.Timeout | null>(null);

  const refresh = useCallback(() => {
    if (refreshTimeoutHandle) return;
    authRefresh({ variables: { token: getAuthToken() } });
    setRefreshTimeoutHandle(setTimeout(refresh, REFRESH_INTERVAL_MS));
  }, [authRefresh, refreshTimeoutHandle]);

  useEffect(() => {
    if (user != null && !refreshTimeoutHandle) {
      refresh();
    }
    return function clear() {
      if (refreshTimeoutHandle == null) return;
      clearTimeout(refreshTimeoutHandle);
      setRefreshTimeoutHandle(null);
    };
  }, [refresh, user, refreshTimeoutHandle]);

  if (loading) return null;
  return (
    // this user, onLogout props can be used by all children components of this provider.
    <UserContext.Provider value={{ user, onLogout: handleLogout }}>
      <Routes />
    </UserContext.Provider>
  );
};
export default App;
