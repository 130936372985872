import React, { useCallback, useMemo, useState } from 'react';

import {
  ProgressBar,
  ProgressBarColor,
} from '@app/components/core/ProgressBar';
import {
  InvoiceFormData,
  InvoiceFormKeys,
} from '@app/views/restaurants/invoice/components/InvoiceForm';
import { AddressProps } from '@app/components/core/Address';
import styles from './MealRequestInvoiceForm.module.scss';
import InvoiceFormStep from './steps/InvoiceFormStep';
import TermsFormStep, {
  TermsFormStepKeys,
  TermsFormStepData,
} from './steps/TermsFormStep';

export interface MealRequestInvoiceFormProps {
  address: AddressProps;
  taxRate: number;
  deliveryDate: Date;
  [InvoiceFormKeys.MEAT_MEALS_COUNT]: number;
  [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: number;
  onSubmit: (data: MealRequestInvoiceFormData, calculatedTotal: number) => void;
}

export type MealRequestInvoiceFormData = {
  invoiceForm: InvoiceFormData;
  termsForm: TermsFormStepData;
};

/**
 * MealRequestInvoiceForm handles the invoice form state including managing the
 * multi-step flow (prices -> confirmation).
 */
export const MealRequestInvoiceForm: React.FC<MealRequestInvoiceFormProps> = ({
  address,
  taxRate: initialTaxRate,
  deliveryDate,
  [InvoiceFormKeys.MEAT_MEALS_COUNT]: numberOfMeatMeals,
  [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: numberOfVegetarianMeals,
  onSubmit,
}: MealRequestInvoiceFormProps) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState<MealRequestInvoiceFormData>({
    invoiceForm: {
      [InvoiceFormKeys.MEAT_MEALS_COUNT]: numberOfMeatMeals,
      [InvoiceFormKeys.MEAT_MEALS_COST]: 0,
      [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: numberOfVegetarianMeals,
      [InvoiceFormKeys.VEGETARIAN_MEALS_COST]: 0,
      [InvoiceFormKeys.TAX_RATE]: initialTaxRate,
    },
    termsForm: {
      [TermsFormStepKeys.TERMS_ACKNOWLEGEMENT]: false,
    },
  });

  const setInvoiceData = useCallback(
    (value: InvoiceFormData) =>
      setData((prevData) => {
        return {
          ...prevData,
          invoiceForm: value,
        };
      }),
    [setData],
  );

  const setTermsData = useCallback(
    (
      value: TermsFormStepData,
      onComplete: (formData: MealRequestInvoiceFormData) => void,
    ) => {
      setData((prevData) => {
        const formData = {
          ...prevData,
          termsForm: value,
        };
        onComplete(formData);
        return formData;
      });
    },
    [setData],
  );

  const handleSubmit = useCallback(
    (formData) => {
      const meatMealCosts =
        formData.invoiceForm[InvoiceFormKeys.MEAT_MEALS_COUNT] *
        formData.invoiceForm[InvoiceFormKeys.MEAT_MEALS_COST];
      const vegetarianMealCosts =
        formData.invoiceForm[InvoiceFormKeys.VEGETARIAN_MEALS_COUNT] *
        formData.invoiceForm[InvoiceFormKeys.VEGETARIAN_MEALS_COST];
      const calculatedTotal =
        (meatMealCosts + vegetarianMealCosts) *
        (1 + formData.invoiceForm[InvoiceFormKeys.TAX_RATE]);
      onSubmit(formData, calculatedTotal);
    },
    [onSubmit],
  );

  const form = useMemo(() => {
    if (step === 0) {
      return (
        <InvoiceFormStep
          initialValues={data.invoiceForm}
          onSuccess={(values) => {
            setInvoiceData(values);
            setStep(1);
          }}
        />
      );
    }

    return (
      <TermsFormStep
        initialValues={data.termsForm}
        recipientAddress={address}
        deliveryDate={deliveryDate}
        onBack={() => setStep(0)}
        onSuccess={(values) => {
          setTermsData(values, handleSubmit);
        }}
      />
    );
  }, [
    step,
    data.termsForm,
    data.invoiceForm,
    address,
    deliveryDate,
    setInvoiceData,
    setTermsData,
    handleSubmit,
  ]);

  return (
    <div>
      <div className={styles['progress-bar']}>
        <ProgressBar
          stepIndex={step}
          totalSteps={2}
          color={ProgressBarColor.Secondary}
        />
      </div>
      <div className={styles.section}>{form}</div>
    </div>
  );
};

export default MealRequestInvoiceForm;
