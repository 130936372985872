import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Navigation.module.scss';

export interface Props {
  key: number;
  title: string;
  href: string;
  alertCount?: number;
  onNavItemClick?: () => void;
  current?: string;
}

const NavItems: React.FC<Props> = ({
  title,
  href,
  alertCount,
  onNavItemClick,
  current,
}: Props) => {
  return (
    <Link
      onClick={onNavItemClick}
      to={`${href}`}
      className={
        current && current === href
          ? `${styles['common-button']} ${styles.clicked}`
          : `${styles['common-button']}`
      }
    >
      {title}
      <span
        className={
          alertCount === undefined
            ? ''
            : `${styles['alert-count']} ${styles.poa}`
        }
      >
        {alertCount}
      </span>
    </Link>
  );
};

export default NavItems;
