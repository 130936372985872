import React from 'react';
import './styles.scss';
import '../styles.scss';

import { TextColor, TextWeight } from '..';

export enum BodySize {
  Large = 'size-body-large',
  Default = 'size-body-default',
  Small = 'size-body-small',
}

export enum WhiteSpace {
  Default = 'white-space-default',
}

export interface BodyTextProps {
  color?: TextColor;
  size?: BodySize;
  weight?: TextWeight;
  whiteSpace?: WhiteSpace;
  children?: React.ReactNode;
}

export const BodyText: React.FC<BodyTextProps> = ({
  color = TextColor.Dark,
  size = BodySize.Default,
  weight = TextWeight.Normal,
  whiteSpace = WhiteSpace.Default,
  children,
}: BodyTextProps) => {
  const themeClasses = ['text', color, size, weight, whiteSpace];
  return <span className={themeClasses.join(' ')}>{children}</span>;
};
