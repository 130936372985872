import { TextColor } from 'components/core/Typography';
import { BodySize, BodyText } from 'components/core/Typography/BodyText';
import React, { useState } from 'react';

import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button';
import { Form, Formik } from 'formik';
import Checkbox from '@app/components/core/Interaction/Checkbox';
import InvoiceFormErrors from '@app/views/restaurants/invoice/components/InvoiceFormErrors';
import Address, { AddressProps } from '@app/components/core/Address';
import DateRenderer from '@app/components/core/DateRenderer';
import { Link } from '@app/components/core/Link';
import styles from './TermsFormStep.module.scss';
import formStyles from '../../MealRequestInvoiceForm.module.scss';

import arrow from './images/arrow.svg';

const SERVICE_AGREEMENT_HREF =
  'https://drive.google.com/file/d/1Bp3QTL9cxqdI5XDLmRbiXIJmoLRD309P/view?usp=sharing';

export interface TermsFormStepProps {
  initialValues: TermsFormStepData;
  recipientAddress: AddressProps;
  deliveryDate: Date;
  onBack: () => void;
  onSuccess: (values: TermsFormStepData) => void;
}

export enum TermsFormStepKeys {
  TERMS_ACKNOWLEGEMENT = 'terms_acknowledgement',
}

export type TermsFormStepData = {
  [TermsFormStepKeys.TERMS_ACKNOWLEGEMENT]: boolean;
};

const TermsFormStep: React.FC<TermsFormStepProps> = ({
  initialValues,
  recipientAddress,
  deliveryDate,
  onBack,
  onSuccess,
}: TermsFormStepProps) => {
  const [canContinue, setCanContinue] = useState(true);
  const [formErrors, setFormErrors] = useState<Map<string, string>>(new Map());
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: Map<string, string> = new Map();
        if (!values[TermsFormStepKeys.TERMS_ACKNOWLEGEMENT]) {
          // Need to sync with garron and lisa for content
          errors.set(
            TermsFormStepKeys.TERMS_ACKNOWLEGEMENT,
            'Please accept the service agreement before continuing.',
          );
        }
        setCanContinue(errors.size === 0);
        setFormErrors(errors);
        return Object.fromEntries(errors);
      }}
      onSubmit={onSuccess}
      validateOnMount
    >
      {(form) => (
        <Form>
          <div className={formStyles.section}>
            <div className={formStyles.title}>
              <BodyText color={TextColor.Light} size={BodySize.Small}>
                <span className={formStyles.bolded}>Delivery Address</span>
              </BodyText>
            </div>
            <div className={formStyles.body}>
              <Address {...recipientAddress} />
            </div>

            <div className={formStyles.title}>
              <BodyText color={TextColor.Light} size={BodySize.Small}>
                <span className={formStyles.bolded}>Delivery Date</span>
              </BodyText>
            </div>
            <div className={formStyles.body}>
              <BodyText color={TextColor.Light} size={BodySize.Small}>
                <DateRenderer date={deliveryDate} />
              </BodyText>
            </div>
          </div>

          <div className={formStyles.divider} />

          <div className={formStyles.section}>
            <div className={formStyles.section}>
              <div className={styles['terms-row']}>
                <div className={styles['terms-checkbox']}>
                  <Checkbox
                    name={TermsFormStepKeys.TERMS_ACKNOWLEGEMENT}
                    checked={
                      form.values[TermsFormStepKeys.TERMS_ACKNOWLEGEMENT]
                    }
                    onChange={(value: boolean) =>
                      form.setFieldValue(
                        TermsFormStepKeys.TERMS_ACKNOWLEGEMENT,
                        value,
                      )
                    }
                  />
                </div>
                <div className={styles['terms-info']}>
                  <BodyText color={TextColor.Light} size={BodySize.Small}>
                    I agree to fulfill this meal request and to Meal
                    Forward&apos;s{' '}
                    <Link
                      to={{ pathname: SERVICE_AGREEMENT_HREF }}
                      target="_blank"
                    >
                      service agreement
                    </Link>
                  </BodyText>
                </div>
              </div>
            </div>

            <InvoiceFormErrors errors={formErrors} />

            <div className={formStyles.section}>
              <Button
                type="button"
                verticalPadding={22}
                theme={{
                  color: ButtonColor.Secondary,
                  size: ButtonSize.Small,
                  width: ButtonWidth.Compact,
                }}
                onClick={onBack}
                iconSrc={arrow}
              />
              <Button
                type="submit"
                disabled={!canContinue}
                verticalPadding={33}
                theme={{
                  color: ButtonColor.Primary,
                  size: ButtonSize.Small,
                  width: ButtonWidth.Compact,
                }}
              >
                Submit Invoice
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TermsFormStep;
