import { gql } from '@apollo/client';

export default gql`
  mutation RestaurantDuplicateCheckQuery(
    $restaurantName: String!
    $addressData: AddressInput!
  ) {
    check_duplication(
      restaurant_name: $restaurantName
      address_data: $addressData
    ) {
      is_duplicated
    }
  }
`;
