import React from 'react';
import Logo from 'components/core/Logo';
import TableList from 'views/restaurants/myOrders/components/utilized-component/TableList';
import styles from './MyOrderPage.module.scss';

export const MyOrderPage: React.FC = () => {
  return (
    <div className={styles['wrap-padding']}>
      <Logo isWithUnderBar />
      <TableList />
    </div>
  );
};

export default MyOrderPage;
