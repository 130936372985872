import { gql } from '@apollo/client';

export default gql`
  mutation AuthRefreshQuery($token: String!) {
    refresh_token(token: $token) {
      payload
      token
    }
  }
`;
