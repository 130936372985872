export enum AppPathType {
  PUBLIC,
  PRIVATE,
  LOGIN,
}

export enum AppPath {
  INDEX,
  MEAL_REQUEST,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  OPEN_SOURCE,
  SIGN_UP,
  LOGIN,
  RESTAURANT_HOME,
  MY_ORDERS,
}

// Utility that tricks the typechecker
export const AppPaths: Map<AppPath, null> = new Map(
  Object.keys(AppPath)
    // eslint-disable-next-line no-restricted-globals
    .filter((key) => !isNaN(Number(key)))
    .map((key) => [Number(key) as AppPath, null]),
);

export interface IAppPathConfig {
  uri: string;
  pathType: AppPathType;
}

export function getAppPathConfig(path: AppPath): IAppPathConfig {
  switch (path) {
    // Public endpoints
    case AppPath.INDEX:
      return {
        uri: '/',
        pathType: AppPathType.PUBLIC,
      };
    case AppPath.MEAL_REQUEST:
      return {
        uri: '/mealrequest',
        pathType: AppPathType.PUBLIC,
      };
    case AppPath.TERMS_OF_USE:
      return {
        uri: '/termsofuse',
        pathType: AppPathType.PUBLIC,
      };
    case AppPath.PRIVACY_POLICY:
      return {
        uri: '/privacypolicy',
        pathType: AppPathType.PUBLIC,
      };
    case AppPath.OPEN_SOURCE:
      return {
        uri: '/open-source',
        pathType: AppPathType.PUBLIC,
      };
    // Auhorization endpoints
    case AppPath.SIGN_UP:
      return {
        uri: '/signup',
        pathType: AppPathType.LOGIN,
      };
    case AppPath.LOGIN:
      return {
        uri: '/login',
        pathType: AppPathType.LOGIN,
      };

    // Logged in endpoints
    case AppPath.RESTAURANT_HOME:
      return {
        uri: '/restaurant',
        pathType: AppPathType.PRIVATE,
      };
    case AppPath.MY_ORDERS:
      return {
        uri: '/myorders',
        pathType: AppPathType.PRIVATE,
      };

    default: {
      const _: never = path;
      throw new Error('unreachable');
    }
  }
}
