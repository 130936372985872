/* eslint-disable react/no-array-index-key */
import React from 'react';

export interface IVerticalSpacerProps {
  verticalSpacerHeight: number;
  children: React.ReactNode;
}

/**
 * VerticalSpacer is a utility component that will automatically
 * intersperse spacers between child elements.
 * @param verticalSpacerHeight Space between child elements
 */
const VerticalSpacer: React.FC<IVerticalSpacerProps> = ({
  verticalSpacerHeight,
  children,
}: IVerticalSpacerProps) => {
  const childrenArray = React.Children.toArray(children);
  return (
    <>
      {childrenArray.map((child, index) => {
        if (child == null) {
          return null;
        }
        return (
          <div key={index}>
            {index === childrenArray.length - 1 ? (
              child
            ) : (
              <div style={{ marginBottom: verticalSpacerHeight }}>{child}</div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default VerticalSpacer;
