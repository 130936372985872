/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import { AppPath, getAppPathConfig } from '@app/paths';
import { TextColor } from '@app/components/core/Typography';
import { BodyText, BodySize } from '@app/components/core/Typography/BodyText';
import Loading from '@app/components/core/Loading';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button';
import {
  InputField,
  InputFieldColor,
} from '@app/components/core/Interaction/InputField';
import { Label } from '@app/components/core/Interaction/Label';
import {
  validateEmail,
  validateZipcode,
  validatePasswordMatch,
  formatToPhone,
  hasEmptyField,
} from '@app/components/core/Validation';
import BackBtn from '@app/icons/back-btn.svg';

import CREATE_QUERY from '@app/views/signup/graphql/queries/RegisterQuery';
import DUPLICATE_CHECK_QUERY from '@app/views/signup/graphql/queries/RestaurantDuplicateCheckQuery';
import { RegisterQuery } from '@app/views/signup/graphql/queries/types/RegisterQuery';
import { RestaurantDuplicateCheckQuery } from '@app/views/signup/graphql/queries/types/RestaurantDuplicateCheckQuery';
import styles from './SignUp.module.scss';

interface ErrorMessageProps {
  error: string | undefined;
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  return <>{error && <div className={styles['error-msg']}>{error}</div>}</>;
};

interface SignUpProps {
  setBarIdx: React.Dispatch<React.SetStateAction<number>>;
}

export const SignUpForm: React.FC<SignUpProps> = ({
  setBarIdx,
}: SignUpProps) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [validating, setValidating] = useState<boolean>(false);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [errors, setErrors] = useState({} as Record<any, string>);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputColor = InputFieldColor.Primary;
  const buttonTheme = {
    color: ButtonColor.Primary,
    size: ButtonSize.Default,
    width: ButtonWidth.Compact,
  };

  const ADDRESS_VALIDATOR_URL = 'https://geocode.search.hereapi.com/v1/geocode';
  // We want to put this into a .env file, but easy to delete and request
  // Also a free server for hobbiest
  const HERE_API_KEY = 'Wehr-1r7XPv4f2fxxD0aCJftE3x-0U2yTk2JOo3V7ko';

  const [register, { loading: registering }] = useMutation<RegisterQuery>(
    CREATE_QUERY,
  );

  const [
    duplicateCheck,
    { loading: duplicateChecking },
  ] = useMutation<RestaurantDuplicateCheckQuery>(DUPLICATE_CHECK_QUERY, {
    onCompleted: async (res) => {
      if (res && !res.check_duplication?.is_duplicated) {
        setErrors((prev) => {
          const state = prev;
          delete state.duplicateRestaurant;
          return { ...state };
        });
      } else {
        setErrors((prev) => ({
          ...prev,
          duplicateRestaurant:
            'The restaurant name and address are already registered. Please check again.',
        }));
      }
    },
    onError: (e) => {
      setErrors((prev) => ({
        ...prev,
        duplicateRestaurant: e.message,
      }));
    },
  });

  interface AddressData {
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
  }

  interface RestaurantData {
    restaurant_name: string;
    phone: string;
    does_delivery: boolean;
    max_meals: string;
    min_meals: string;
    extra_pay_method: boolean;
  }

  interface FormValues {
    addressData: AddressData;
    restaurantData: RestaurantData;
    email: string;
    contact: string;
    password: string;
    confirmPassword: string;
  }

  const checkRestaurantName = (restaurantName: string) => {
    if (restaurantName === '') {
      setErrors((prev) => ({
        ...prev,
        restaurantName: 'Restaurant name is required',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.restaurantName;
        return { ...state };
      });
    }
  };

  const checkAddress = async (address: AddressData) => {
    const hasAddress = checkStreetAddress(address.address1);
    const hasCity = checkCity(address.city);
    const hasStateAndZip = checkStateAndZipcode(address);

    if (hasAddress && hasCity && hasStateAndZip) {
      const addressToValdiate = [
        address.address1,
        address.city,
        address.city,
        address.zipcode,
        address.state,
      ];

      const { data } = await axios.get(ADDRESS_VALIDATOR_URL, {
        params: {
          q: addressToValdiate.join(' '),
          apiKey: HERE_API_KEY,
        },
      });

      checkAddressValid(data.items);
    }
  };

  const checkAddressValid = (addressItems: Array<{ resultType: string }>) => {
    if (
      addressItems.length === 0 ||
      addressItems.length > 1 ||
      (addressItems.length === 1 &&
        addressItems[0].resultType !== 'houseNumber')
    ) {
      setErrors((prev) => ({
        ...prev,
        address: 'Please enter a valid address',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.address;
        return { ...state };
      });
    }
  };

  const checkStreetAddress = (address: string) => {
    if (address.length === 0) {
      setErrors((prev) => ({
        ...prev,
        streetAddress: 'Address is required',
      }));

      return false;
    }
    setErrors((prev) => {
      const state = prev;
      delete state.streetAddress;
      return { ...state };
    });

    return true;
  };

  const checkCity = (city: string) => {
    if (city.length === 0) {
      setErrors((prev) => ({
        ...prev,
        city: 'City is required',
      }));

      return false;
    }
    setErrors((prev) => {
      const state = prev;
      delete state.city;
      return { ...state };
    });

    return true;
  };

  const checkStateAndZipcode = (address: AddressData) => {
    if (address.state.length === 0 && address.zipcode.length === 0) {
      setErrors((prev) => ({
        ...prev,
        stateAndZipCode: 'State and zipcode is required',
      }));
      return false;
    }

    if (address.state.length === 0) {
      setErrors((prev) => ({
        ...prev,
        stateAndZipCode: 'State is required',
      }));
      return false;
    }

    if (address.zipcode.length === 0) {
      setErrors((prev) => ({
        ...prev,
        stateAndZipCode: 'Zipcode is required',
      }));
      return false;
    }

    if (!validateZipcode(address.zipcode)) {
      setErrors((prev) => ({
        ...prev,
        stateAndZipCode: 'Zipcode requires 5 digits',
      }));
      return false;
    }

    setErrors((prev) => {
      const state = prev;
      delete state.stateAndZipCode;
      return { ...state };
    });
    return true;
  };

  const checkEmail = (email: string) => {
    if (email.length === 0) {
      setErrors((prev) => ({ ...prev, email: 'Email address is required' }));
    } else if (!validateEmail(email)) {
      setErrors((prev) => ({ ...prev, email: 'Invalid email address' }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.email;
        return { ...state };
      });
    }
  };

  const checkZipcode = (z: string) => {
    if (!validateZipcode(z)) {
      setErrors((prev) => ({ ...prev, zipcode: 'Zipcode requires 5 digits' }));
    } else
      setErrors((prev) => {
        const state = prev;
        delete errors.zipcode;
        return { ...state };
      });
  };

  const checkPasswordMatch = (p: string, cp: string) => {
    if (!validatePasswordMatch(p, cp)) {
      setErrors((prev) => ({
        ...prev,
        password: 'Please make sure your passwords match',
      }));
    } else
      setErrors((prev) => {
        const state = prev;
        delete errors.password;
        return { ...state };
      });
  };

  const isWholeNumber = (n: number) => {
    return n % 1 === 0;
  };

  const checkMealNum = (minMeals: string, maxMeals: string) => {
    const min = Number(minMeals);
    const max = Number(maxMeals);

    if (min <= 0 && max <= 0) {
      setErrors((prev) => ({
        ...prev,
        meal_num: 'Please request more than 1 meal at least',
      }));
    } else if (!isWholeNumber(min) || !isWholeNumber(max)) {
      setErrors((prev) => ({
        ...prev,
        meal_num: 'Meals must be in whole numbers',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.meal_num;
        return { ...state };
      });
    }
  };

  const checkPhoneNumber = (phone: string) => {
    if (phone.length !== 14) {
      setErrors((prev) => ({
        ...prev,
        phone_number: 'Phone number is required',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.phone_number;
        return { ...state };
      });
    }
  };

  const checkContactPerson = (contactPerson: string) => {
    if (contactPerson.length === 0) {
      setErrors((prev) => ({
        ...prev,
        contact: 'Contact person is required',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.contact;
        return { ...state };
      });
    }
  };

  const checkRestaurant = (restaurant: RestaurantData) => {
    const { max_meals, min_meals, phone, restaurant_name } = restaurant;

    checkRestaurantName(restaurant_name);
    checkMealNum(min_meals, max_meals);
    checkPhoneNumber(phone);
  };

  const initialValues = {
    addressData: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: 'US',
      zipcode: '',
    },
    restaurantData: {
      restaurant_name: '',
      phone: '',
      does_delivery: false,
      max_meals: '',
      min_meals: '',
      extra_pay_method: false,
    },
    email: '',
    contact: '',
    password: '',
    confirmPassword: '',
  };

  const onFormSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>,
  ) => {
    const val = {
      addressData: values.addressData,
      restaurantData: values.restaurantData,
      email: values.email,
      contact: values.contact,
      password: values.password,
    };

    if (!isLoading && !hasEmptyField(val, ['address2'])) {
      setIsLoading(true);
      try {
        const res = await register({ variables: val });
        if (res) {
          resetForm();
          history.push('/login');
        }
        setIsLoading(false);
      } catch (e) {
        if (
          e.message.includes(
            `duplicate key value violates unique constraint "auth_user_username_key"`,
          )
        )
          setErrors((prev) => ({
            ...prev,
            duplicateID: 'This email has been already registered.',
          }));
        else
          setErrors((prev) => {
            const state = prev;
            delete errors.duplicateID;
            return { ...state };
          });

        setIsLoading(false);
      }
    }
  };

  const validate = async (values: FormValues) => {
    setIsLoading(true);
    const address = values.addressData;
    const restaurant = values.restaurantData;
    const { contact, email } = values;

    if (step === 1) {
      await duplicateCheck({
        variables: {
          restaurantName: restaurant.restaurant_name,
          addressData: address,
        },
      });

      await checkAddress(address);

      checkRestaurant(restaurant);

      checkContactPerson(contact);
      checkEmail(email);
    }
    setIsLoading(false);
    return errors;
  };

  const validateFormOneAndMoveOn = async (values: FormValues) => {
    await validate(values);

    setValidating(true);
  };

  const hasError = (error: string) => {
    return error && error.length > 0;
  };

  /*
    We are using `validating` here because setState takes time to do, and is
    not asynchronous itself

    Therefore, we use `useEffect` to wait for the state change of `validating`, 
    and then we go on with the validating steps (check for errors and such)
  */
  useEffect(() => {
    const formHasErrors = Object.keys(errors).length > 0;

    if (validating && !formHasErrors && step === 1) {
      setStep(2);
      setBarIdx(1);
    }

    setValidating(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validating]);

  // prettier-ignore
  const states = ['AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL',
  'GA','GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA','MI',
  'MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','MP','OH','OK',
  'OR','PW','PA','PR','RI','SC','SD','TN','TX','UT','VT','VI','VA','WA','WV',
  'WI','WY'];

  const stateOptions = states.map((state) => (
    <option key={state} value={state} label={state} />
  ));

  stateOptions.unshift(<option value="" key="select" label="Select" />);

  return (
    <section className={styles['register-form-wrapper']}>
      <div className={styles['register-form']}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onFormSubmit}
        >
          {(form: FormikProps<FormValues>) => (
            <Form>
              <div hidden={step !== 1}>
                <InputField
                  color={inputColor}
                  type="text"
                  label="Name of Restaurant"
                  name="restaurantData.restaurant_name"
                  hasError={hasError(errors.restaurantName)}
                  onBlur={(e) => {
                    checkRestaurantName(e.target.value);
                  }}
                />
                <ErrorMessage error={errors.restaurantName} />

                <div
                  className={
                    hasError(errors.address) ? styles['address-error'] : ''
                  }
                >
                  {hasError(errors.address) && (
                    <div className={styles['error-msg']}>
                      Please enter a valid address
                    </div>
                  )}
                  <InputField
                    color={inputColor}
                    type="text"
                    placeholder="123 Street"
                    label="Address"
                    name="addressData.address1"
                    hasError={hasError(errors.streetAddress)}
                    onBlur={() => {
                      checkStreetAddress(form.values.addressData.address1);
                    }}
                  />
                  <ErrorMessage error={errors.streetAddress} />

                  <InputField
                    color={inputColor}
                    type="text"
                    placeholder="Apt 220"
                    label="Address 2 (Optional)"
                    name="addressData.address2"
                  />

                  <InputField
                    color={inputColor}
                    type="text"
                    placeholder="San Bruno"
                    label="City"
                    name="addressData.city"
                    hasError={hasError(errors.city)}
                    onBlur={() => {
                      checkCity(form.values.addressData.city);
                    }}
                  />
                  <ErrorMessage error={errors.city} />

                  <div className={styles['fifty-form']}>
                    <Label label="State" htmlFor="state">
                      <InputField
                        color={inputColor}
                        type="text"
                        name="addressData.state"
                        hidden
                      />
                      <select
                        name="state"
                        onChange={(e) => {
                          form.setFieldValue(
                            'addressData.state',
                            e.target.value,
                          );
                        }}
                        onBlur={() => {
                          checkStateAndZipcode(form.values.addressData);
                        }}
                      >
                        {stateOptions}
                      </select>
                    </Label>
                    <InputField
                      color={inputColor}
                      type="text"
                      label="ZIP Code"
                      placeholder="94301"
                      name="addressData.zipcode"
                      hasError={hasError(errors.stateAndZipCode)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const zipcode = e.target.value
                          .replace(/\D/g, '')
                          .substring(0, 5);
                        form.setFieldValue('addressData.zipcode', zipcode);
                        checkZipcode(zipcode);
                      }}
                      onBlur={() => {
                        checkStateAndZipcode(form.values.addressData);
                      }}
                    />
                  </div>
                  <ErrorMessage error={errors.stateAndZipCode} />
                </div>

                <InputField
                  color={inputColor}
                  type="email"
                  label="Email Address"
                  placeholder="email@website.com"
                  name="email"
                  hasError={hasError(errors.email)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    form.setFieldValue('email', e.target.value);
                    checkEmail(e.target.value);
                  }}
                  onBlur={(e) => {
                    checkEmail(e.target.value);
                  }}
                />

                <ErrorMessage error={errors.email} />

                <InputField
                  color={inputColor}
                  type="text"
                  label="Phone Number"
                  placeholder="(555) 891-4135"
                  name="restaurantData.phone"
                  maxLength={16}
                  hasError={hasError(errors.phone_number)}
                  onChange={(e) =>
                    form.setFieldValue('restaurantData.phone', formatToPhone(e))
                  }
                  onBlur={(e) => {
                    checkPhoneNumber(e.target.value);
                  }}
                />

                <ErrorMessage error={errors.phone_number} />

                <InputField
                  color={inputColor}
                  type="text"
                  label="Contact Person"
                  placeholder="Jane Doe"
                  name="contact"
                  hasError={hasError(errors.contact)}
                  onBlur={(e) => {
                    checkContactPerson(e.target.value);
                  }}
                />

                <ErrorMessage error={errors.contact} />

                <Label label="With 2 days notice, what is the minimum and maximum nuber of meals you can fulfill?">
                  <div className={styles['fifty-form']}>
                    <InputField
                      type="number"
                      color={InputFieldColor.Primary}
                      name="restaurantData.min_meals"
                      placeholder="Min"
                      onKeyDown={(e) =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      hasError={hasError(errors.meal_num)}
                      onChange={(e) => {
                        form.setFieldValue(
                          'restaurantData.min_meals',
                          e.target.value,
                        );
                        checkMealNum(
                          e.target.value,
                          form.values.restaurantData.max_meals,
                        );
                      }}
                    />
                    <InputField
                      type="number"
                      color={InputFieldColor.Primary}
                      name="restaurantData.max_meals"
                      placeholder="Max"
                      onKeyDown={(e) =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      hasError={hasError(errors.meal_num)}
                      onChange={(e) => {
                        form.setFieldValue(
                          'restaurantData.max_meals',
                          e.target.value,
                        );
                        checkMealNum(
                          form.values.restaurantData.min_meals,
                          e.target.value,
                        );
                      }}
                    />
                  </div>
                </Label>

                <ErrorMessage error={errors.meal_num} />

                <Label label="Do you deliver?">
                  <div className={styles['quarter-form']}>
                    <InputField // TODO : need to change this button-like radio button.
                      type="radio"
                      color={inputColor}
                      value="Yes"
                      label="Yes"
                      checked={
                        form.values.restaurantData.does_delivery === true
                      }
                      name="restaurantData.does_delivery"
                      onChange={() =>
                        form.setFieldValue('restaurantData.does_delivery', true)
                      }
                    />
                    <InputField
                      type="radio"
                      color={inputColor}
                      value="No"
                      label="No"
                      name="restaurantData.does_delivery"
                      checked={
                        form.values.restaurantData.does_delivery === false
                      }
                      onChange={() =>
                        form.setFieldValue(
                          'restaurantData.does_delivery',
                          false,
                        )
                      }
                    />
                  </div>
                </Label>

                <Label label="Do you accept Venmo or Paypal?">
                  <div className={styles['quarter-form']}>
                    <InputField
                      type="radio"
                      color={inputColor}
                      value="Yes"
                      label="Yes"
                      name="restaurantData.extra_pay_method"
                      checked={
                        form.values.restaurantData.extra_pay_method === true
                      }
                      onChange={() =>
                        form.setFieldValue(
                          'restaurantData.extra_pay_method',
                          true,
                        )
                      }
                    />
                    <InputField
                      type="radio"
                      color={inputColor}
                      value="No"
                      label="No"
                      name="restaurantData.extra_pay_method"
                      checked={
                        form.values.restaurantData.extra_pay_method === false
                      }
                      onChange={() =>
                        form.setFieldValue(
                          'restaurantData.extra_pay_method',
                          false,
                        )
                      }
                    />
                  </div>
                </Label>

                <ErrorMessage error={errors.duplicateRestaurant} />

                <Button
                  type="button"
                  theme={buttonTheme}
                  onClick={() => validateFormOneAndMoveOn(form.values)}
                >
                  {isLoading ? (
                    <Loading isLoading={isLoading || duplicateChecking} />
                  ) : (
                    'Next'
                  )}
                </Button>
              </div>
              <div className="create" hidden={step !== 2}>
                <InputField
                  color={InputFieldColor.Primary}
                  type="text"
                  name="email"
                  label="Email Address"
                  value={form.values.email}
                  disabled
                />
                <InputField
                  color={InputFieldColor.Primary}
                  type="password"
                  label="Password"
                  name="password"
                  hasError={hasError(errors.password || errors.duplicateID)}
                />
                <InputField
                  color={InputFieldColor.Primary}
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  hasError={hasError(errors.password || errors.duplicateID)}
                  onChange={(e) => {
                    form.setFieldValue('confirmPassword', e.target.value);
                    checkPasswordMatch(form.values.password, e.target.value);
                  }}
                />

                <ErrorMessage error={errors.password} />
                <ErrorMessage error={errors.duplicateID} />

                <div className={styles.buttons}>
                  <Button
                    type="button"
                    theme={{ ...buttonTheme, color: ButtonColor.Secondary }}
                    onClick={() => {
                      setErrors((prev) => {
                        const state = prev;
                        delete errors.duplicateID;
                        return { ...state };
                      });
                      setStep(1);
                      setBarIdx(0);
                    }}
                  >
                    <img src={BackBtn} alt="back" />
                  </Button>
                </div>
                <div className={styles.buttons}>
                  <Button type="submit" theme={buttonTheme}>
                    {isLoading ? (
                      <Loading isLoading={isLoading || registering} />
                    ) : (
                      'Create Account'
                    )}
                  </Button>
                </div>
              </div>

              <BodyText color={TextColor.Light} size={BodySize.Default}>
                Already have an account?{' '}
                <Link to={getAppPathConfig(AppPath.LOGIN).uri}>Sign In</Link>
              </BodyText>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default SignUpForm;
