import React, { useMemo, useState } from 'react';

import { BodySize, BodyText } from '@app/components/core/Typography/BodyText';
import { TextColor } from '@app/components/core/Typography';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button';
import InvoiceForm, {
  InvoiceFormData,
  InvoiceFormKeys,
} from '@app/views/restaurants/invoice/components/InvoiceForm';
import { AddressProps } from '@app/components/core/Address';
import DateRenderer from '@app/components/core/DateRenderer';
import useMarkClaimDeliveredMutation from '../../useMarkClaimDeliveredMutation';

import styles from './MyOrderDetailForm.module.scss';

interface MyOrderDetailFormProps {
  claimID: number;
  address: AddressProps;
  orderStatus: string;
  deliveryAt: string;
  [InvoiceFormKeys.MEAT_MEALS_COUNT]: number;
  [InvoiceFormKeys.MEAT_MEALS_COST]: number;
  [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: number;
  [InvoiceFormKeys.VEGETARIAN_MEALS_COST]: number;
  taxRate: number;
}

/**
 * MyOrderDetailForm handles order detail form
 * including the invoice and delivery information.
 */
export const MyOrderDetailForm: React.FC<MyOrderDetailFormProps> = ({
  claimID,
  address,
  orderStatus,
  deliveryAt,
  [InvoiceFormKeys.MEAT_MEALS_COUNT]: numberOfMeatMeals,
  [InvoiceFormKeys.MEAT_MEALS_COST]: costOfMeatMeals,
  [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: numberOfVegetarianMeals,
  [InvoiceFormKeys.VEGETARIAN_MEALS_COST]: costOfVegetarianMeals,
  taxRate,
}: MyOrderDetailFormProps) => {
  const [data, _] = useState<InvoiceFormData>({
    // TODO(Yeseul Lee): wire in graphql based on request and claim info
    [InvoiceFormKeys.MEAT_MEALS_COUNT]: numberOfMeatMeals,
    [InvoiceFormKeys.MEAT_MEALS_COST]: costOfMeatMeals,
    [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: numberOfVegetarianMeals,
    [InvoiceFormKeys.VEGETARIAN_MEALS_COST]: costOfVegetarianMeals,
    [InvoiceFormKeys.TAX_RATE]: taxRate,
  });

  const form = useMemo(() => {
    // TODO(Yeseul Lee): Use helper components for address and date
    return (
      <div>
        <div className={styles.divider} />
        <BodyText color={TextColor.Light} size={BodySize.Default}>
          <span className={styles.bolded}>Order Status</span>
        </BodyText>
        <br />
        <BodyText color={TextColor.Light} size={BodySize.Default}>
          <span className={styles.Light}>{orderStatus}</span>
        </BodyText>
        <div className={styles.section}>
          <div className={styles['form-title']}>
            <div className={styles.divider} />
            <BodyText color={TextColor.Light} size={BodySize.Default}>
              <span className={styles.bolded}>Order Price</span>
            </BodyText>
          </div>
          <div>
            <BodyText color={TextColor.Light} size={BodySize.Default}>
              This is the amount you will be paid for completing this order.
            </BodyText>
          </div>
        </div>
        <div className={styles['invoice-form']}>
          <InvoiceForm
            disabled
            initialValues={data}
            // onSubmit={undefined}
            // onValidate={undefined}
          />
        </div>
        <div className={styles.divider} />
        <div className={styles.section}>
          <div className={styles.title}>
            <BodyText color={TextColor.Light} size={BodySize.Default}>
              <span className={styles.bolded}>Delivery Address</span>
            </BodyText>
          </div>
          <div className={styles.body}>
            <BodyText color={TextColor.Light} size={BodySize.Default}>
              {`${address.line1}, ${address.city},`}
              <br />
              {`${address.state}, ${address.postalCode}`}
            </BodyText>
          </div>

          <div className={styles.title}>
            <BodyText color={TextColor.Light} size={BodySize.Default}>
              <span className={styles.bolded}>Delivery Date</span>
            </BodyText>
          </div>
          <div className={styles.body}>
            <BodyText color={TextColor.Light} size={BodySize.Default}>
              <DateRenderer date={new Date(deliveryAt)} />
            </BodyText>
          </div>
        </div>
      </div>
    );

    // Not required to re-render on every dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [mutate] = useMarkClaimDeliveredMutation();

  return (
    <div>
      {orderStatus === 'CONFIRMED' ? (
        <div className={styles.buttons}>
          <Button
            type="button"
            verticalPadding={37}
            theme={{
              color: ButtonColor.Primary,
              size: ButtonSize.Small,
              width: ButtonWidth.Compact,
            }}
            onClick={() => mutate({ variables: { claimID } })}
          >
            Mark as Delivered
          </Button>
        </div>
      ) : null}
      <div className={styles.section}>{form}</div>
    </div>
  );
};

export default MyOrderDetailForm;
