import React from 'react';
import { Link } from 'react-router-dom';

import { AppPath, getAppPathConfig } from '@app/paths';
import UserContext from '@app/UserContext';

import loginBtn from '@app/views/landing/images/sign-in-btn.svg';
import myAccountBtn from '@app/views/landing/images/my-account.png';

const HeaderButton: React.FC = () => {
  const { user } = React.useContext(UserContext);

  return (
    <div>
      {user ? (
        <Link to={getAppPathConfig(AppPath.RESTAURANT_HOME).uri}>
          <img
            src={myAccountBtn}
            alt="my-account"
            width="150"
            style={{ mixBlendMode: 'multiply' }}
          />
        </Link>
      ) : (
        <Link to={getAppPathConfig(AppPath.LOGIN).uri}>
          <img src={loginBtn} alt="login" />
        </Link>
      )}
    </div>
  );
};

export default HeaderButton;
