import React, { useCallback } from 'react';
import styles from './Checkbox.module.scss';

export interface CheckboxProps {
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

/**
 * Checkbox is a stylized input checkbox for managing on/off states
 *
 * @param name Input unique name
 * @param checked Whether the element is in the value true state
 * @param disabled Whether the element can be interacted with
 * @param onChange Event listener for user interaction
 */
const Checkbox: React.FC<CheckboxProps> = ({
  name,
  checked = false,
  disabled,
  onChange,
}: CheckboxProps) => {
  const handleChange = useCallback(() => {
    onChange(!checked);
  }, [checked, onChange]);

  return (
    <input
      className={styles.checkbox}
      name={name}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};
Checkbox.defaultProps = {
  disabled: false,
};

export default Checkbox;
