import React from 'react';

import Grid from '@material-ui/core/Grid';

import Header from './components/Header';
import Main from './components/Main';
import OurImpact from './components/OurImpact';
import MainCarousel from './components/Carousel/index';
import HowItWorks from './components/HowItWorks';
import Footer from './components/Footer';

import styles from './Landing.module.scss';

export default function LandingPage(): JSX.Element {
  return (
    <Grid container justify="center">
      <div className={styles['header-wrapper']}>
        <Header />
      </div>
      <Main />
      <OurImpact />
      <MainCarousel />
      <HowItWorks />
      <Footer />
    </Grid>
  );
}
