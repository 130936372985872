/**
 * env.tsx is an easy way to reference
 * runtime environment variables in code
 * that will be applicable to both our React app
 * and Storybook apps.
 */

const prefixes = ['REACT_APP_', 'STORYBOOK_'];

enum EnvironmentVariable {
  GQL_ENV = 'GQL_ENV',
}

/**
 * getEnv will cycle through the app-exposed environment
 * variables and fetch the first value that is not undefined.
 *
 * Example:
 * - getEnv('GQL_ENV') to resolve to either REACT_APP_GQL_ENV or
 *   STORYBOOK_GQL_ENV, whichever returns a defined value first.
 *
 * @param variable key of the environment variable
 */
export function getEnv(variable: string): string | undefined {
  const keys = prefixes.map((prefix) => prefix.concat(variable));
  const key = keys.find((k) => process.env[k] != null);
  if (key == null) {
    return undefined;
  }

  return process.env[key];
}

// It is also useful to define our environment variable getters

export function getGQLEnv(): string | undefined {
  return process.env.REACT_APP_GQL_ENV ?? getEnv(EnvironmentVariable.GQL_ENV);
}
