/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import Loading from '@app/components/core/Loading';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button';
import {
  InputField,
  InputFieldColor,
} from '@app/components/core/Interaction/InputField';
import { TextColor } from '@app/components/core/Typography';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText';
import { BodyText, BodySize } from '@app/components/core/Typography/BodyText';
import {
  validateEmail,
  validateZipcode,
  formatToPhone,
  hasEmptyField,
} from '@app/components/core/Validation';
import { Label } from '@app/components/core/Interaction/Label';
import DateRenderer from '@app/components/core/DateRenderer';
import REQUEST_QUERY from '@app/views/mealRequest/graphql/queries/MealRequestQuery';
import { MealRequestQuery } from '@app/views/mealRequest/graphql/queries/types/MealRequestQuery';
import axios from 'axios';

import Check from '../icons/round-check.svg';
import styles from './mealRequestForm.module.scss';

const ADDRESS_VALIDATOR_URL = 'https://geocode.search.hereapi.com/v1/geocode';
// We want to put this into a .env file, but easy to delete and request
// Also a free server for hobbiest
const HERE_API_KEY = 'Wehr-1r7XPv4f2fxxD0aCJftE3x-0U2yTk2JOo3V7ko';

interface ErrorMessageProps {
  error: string | undefined;
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  return <>{error && <div className={styles['error-msg']}>{error}</div>}</>;
};

const hasError = (error: string) => {
  return error && error.length > 0;
};

interface RecipientData {
  organization_name: string;
  email: string;
  phone_number: string;
  contact_person: string;
}

interface AddressData {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
}

interface MealRequestData {
  meat_meals: string;
  veggie_meals: string;
  comment: string;
  delivery_time: string;
}

interface FormValues {
  recipient_data: RecipientData;
  address_data: AddressData;
  meal_request_data: MealRequestData;
}

export const RequestMealForm: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [request] = useMutation<MealRequestQuery>(REQUEST_QUERY, {});
  const [confirmInfo, setConfirmInfo] = useState<any>();
  const [errors, setErrors] = useState({} as Record<any, string>);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const deliveryTime = useRef<string>('');
  const deliveryDate = useRef<string>('');

  const setMealTimes = (form: any) => {
    if (deliveryDate.current.length > 0 && deliveryTime.current.length > 0) {
      form.setFieldValue(
        'meal_request_data.delivery_time',
        new Date(
          `${deliveryDate.current} ${deliveryTime.current}`,
        ).toISOString(),
      );
    } else {
      form.setFieldValue('meal_request_data.delivery_time', '');
    }
  };

  const buttonTheme = {
    color: ButtonColor.Primary,
    size: ButtonSize.Small,
    width: ButtonWidth.Compact,
  };
  const inputColor = InputFieldColor.Primary;

  const checkEmail = (email: string) => {
    if (email.length === 0) {
      setErrors((prev) => ({ ...prev, email: 'Email address is required' }));
    } else if (!validateEmail(email)) {
      setErrors((prev) => ({ ...prev, email: 'Invalid email address' }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.email;
        return { ...state };
      });
    }
  };

  const checkOrganizationName = (organizationName: string) => {
    if (organizationName.length === 0) {
      setErrors((prev) => ({
        ...prev,
        organization_name: 'Organization name is required',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.organization_name;
        return { ...state };
      });
    }
  };

  const checkPhoneNumber = (phone: string) => {
    if (phone.length !== 14) {
      setErrors((prev) => ({
        ...prev,
        phone_number: 'Phone number is required',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.phone_number;
        return { ...state };
      });
    }
  };

  const checkContactPerson = (contactPerson: string) => {
    if (contactPerson.length === 0) {
      setErrors((prev) => ({
        ...prev,
        contact: 'Contact person is required',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.contact;
        return { ...state };
      });
    }
  };

  const checkMainAddress = (address: string) => {
    if (address.length === 0) {
      setErrors((prev) => ({
        ...prev,
        mainAddress: 'Address is required',
      }));

      return false;
    }

    setErrors((prev) => {
      const state = prev;
      delete state.mainAddress;
      return { ...state };
    });
    return true;
  };

  const checkCity = (city: string) => {
    if (city.length === 0) {
      setErrors((prev) => ({
        ...prev,
        city: 'City is required',
      }));

      return false;
    }
    setErrors((prev) => {
      const state = prev;
      delete state.city;
      return { ...state };
    });

    return true;
  };

  const checkStateAndZipcode = (address: AddressData) => {
    if (address.state.length === 0 && address.zipcode.length === 0) {
      setErrors((prev) => ({
        ...prev,
        stateAndZipCode: 'State and zipcode is required',
      }));
    } else if (address.state.length === 0) {
      setErrors((prev) => ({
        ...prev,
        stateAndZipCode: 'State is required',
      }));
    } else if (address.zipcode.length === 0) {
      setErrors((prev) => ({
        ...prev,
        stateAndZipCode: 'Zipcode is required',
      }));
    } else if (!validateZipcode(address.zipcode)) {
      setErrors((prev) => ({
        ...prev,
        stateAndZipCode: 'Zipcode requires 5 digits',
      }));
    }
    setErrors((prev) => {
      const state = prev;
      delete state.stateAndZipCode;
      return { ...state };
    });

    return true;
  };

  const checkAddress = async (address: AddressData) => {
    const hasAddress = checkMainAddress(address.address1);
    const hasCity = checkCity(address.city);
    const hasStateAndZip = checkStateAndZipcode(address);

    if (hasAddress && hasCity && hasStateAndZip) {
      const addressToValdiate = [
        address.address1,
        address.city,
        address.city,
        address.zipcode,
        address.state,
      ];

      const { data } = await axios.get(ADDRESS_VALIDATOR_URL, {
        params: {
          q: addressToValdiate.join(' '),
          apiKey: HERE_API_KEY,
        },
      });

      checkAddressValid(data.items);
    }
  };

  const checkAddressValid = (addressItems: Array<{ resultType: string }>) => {
    if (
      addressItems.length === 1 &&
      addressItems[0].resultType === 'houseNumber'
    ) {
      setErrors((prev) => {
        const state = prev;
        delete state.address;
        return { ...state };
      });
    } else {
      setErrors((prev) => ({
        ...prev,
        address: 'Please enter a valid address',
      }));
    }
  };

  const isWholeNumber = (n: number) => {
    return n % 1 === 0;
  };

  const checkMealNum = (meatMeals: string, veggieMeals: string) => {
    const meat = Number(meatMeals);
    const veggie = Number(veggieMeals);

    if (meat <= 0 && veggie <= 0) {
      setErrors((prev) => ({
        ...prev,
        meal_num: 'Please request more than 1 meal at least',
      }));
    } else if (!isWholeNumber(meat) || !isWholeNumber(veggie)) {
      setErrors((prev) => ({
        ...prev,
        meal_num: 'Meals must be in whole numbers',
      }));
    } else if (meatMeals === '' || veggieMeals === '') {
      setErrors((prev) => ({
        ...prev,
        meal_num: 'Please enter 0 or more for each meal type.',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.meal_num;
        return { ...state };
      });
    }
  };

  const checkDeliveryTime = (deliveryDetails: string) => {
    if (deliveryDetails.length === 0) {
      setErrors((prev) => ({
        ...prev,
        delivery_time: 'Delivery time is required',
      }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.delivery_time;
        return { ...state };
      });
    }
  };

  // prettier-ignore
  const states = ['AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL',
  'GA','GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA','MI',
  'MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','MP','OH','OK',
  'OR','PW','PA','PR','RI','SC','SD','TN','TX','UT','VT','VI','VA','WA','WV',
  'WI','WY'];

  const stateOptions = states.map((state) => (
    <option key={state} value={state} label={state} />
  ));

  stateOptions.unshift(<option value="" key="select" label="Select" />);

  const initialValues = {
    recipient_data: {
      organization_name: '',
      email: '',
      phone_number: '',
      contact_person: '',
    },
    address_data: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: 'US',
      zipcode: '',
    },
    meal_request_data: {
      meat_meals: '',
      veggie_meals: '',
      comment: '',
      delivery_time: '',
    },
  };

  const validate = (values: FormValues) => {
    const recipient = values.recipient_data;
    const address = values.address_data;
    const mealRequest = values.meal_request_data;

    checkEmail(recipient.email);
    checkOrganizationName(recipient.organization_name);
    checkPhoneNumber(recipient.phone_number);
    checkContactPerson(recipient.contact_person);

    checkAddress(address);

    checkMealNum(mealRequest.meat_meals, mealRequest.veggie_meals);
    checkDeliveryTime(mealRequest.delivery_time);

    return errors;
  };

  const onFormSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>,
  ) => {
    if (!hasEmptyField(values, ['address2', 'comment']) && !loading) {
      try {
        setLoading(true);
        const res = await request({ variables: values });
        if (res) {
          setStep(1);
          resetForm();
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.message.indexOf('Address') > -1) {
          setErrors((prev) => ({ ...prev, address: 'Address is invalid' }));
        }
      }
    }
  };

  if (step === 0) {
    return (
      <div className={styles['request-form-wrapper']}>
        <div className={styles['request-form']}>
          <Formik
            initialValues={initialValues}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onFormSubmit}
          >
            {(form: FormikProps<FormValues>) => (
              <Form>
                <InputField
                  color={inputColor}
                  type="email"
                  label="Email Address"
                  placeholder="email@website.com"
                  name="recipient_data.email"
                  hasError={hasError(errors.email)}
                  onChange={(e) => {
                    form.setFieldValue('recipient_data.email', e.target.value);
                  }}
                  onBlur={(e) => {
                    checkEmail(e.target.value);
                  }}
                />

                <ErrorMessage error={errors.email} />

                <InputField
                  color={inputColor}
                  type="text"
                  label="Organization Name"
                  placeholder="Stanford Hospital COVID-19 ICU"
                  name="recipient_data.organization_name"
                  hasError={hasError(errors.organization_name)}
                  onBlur={(e) => {
                    checkOrganizationName(e.target.value);
                  }}
                />

                <ErrorMessage error={errors.organization_name} />

                <div
                  className={
                    hasError(errors.address) ? styles['address-error'] : ''
                  }
                >
                  {hasError(errors.address) && (
                    <div className={styles['error-msg']}>
                      Please enter a valid address
                    </div>
                  )}
                  <InputField
                    color={inputColor}
                    type="text"
                    placeholder="123 Street Apt. 230"
                    label="Address"
                    name="address_data.address1"
                    hasError={hasError(errors.mainAddress)}
                    onBlur={() => {
                      checkMainAddress(form.values.address_data.address1);
                    }}
                  />

                  <ErrorMessage error={errors.mainAddress} />

                  <InputField
                    color={inputColor}
                    type="text"
                    placeholder="Apt 220"
                    label="Address 2 (Optional)"
                    name="address_data.address2"
                  />
                  <InputField
                    color={inputColor}
                    type="text"
                    placeholder="San Bruno"
                    label="City"
                    name="address_data.city"
                    hasError={hasError(errors.city)}
                    onBlur={(e) => {
                      checkCity(e.target.value);
                    }}
                  />

                  <ErrorMessage error={errors.city} />

                  <div className={styles['fifty-form']}>
                    <Label label="State" htmlFor="state">
                      <InputField
                        color={inputColor}
                        type="text"
                        name="address_data.state"
                        hasError={hasError(errors.stateAndZipCode)}
                        hidden
                      />
                      <select
                        name="state"
                        onChange={(e) => {
                          form.setFieldValue(
                            'address_data.state',
                            e.target.value,
                          );
                        }}
                        onBlur={() => {
                          checkStateAndZipcode(form.values.address_data);
                        }}
                      >
                        {stateOptions}
                      </select>
                    </Label>
                    <InputField
                      color={inputColor}
                      type="text"
                      label="ZIP Code"
                      placeholder="94301"
                      name="address_data.zipcode"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const zipcode = e.target.value
                          .replace(/\D/g, '')
                          .substring(0, 5);
                        form.setFieldValue('address_data.zipcode', zipcode);
                      }}
                      hasError={hasError(errors.stateAndZipCode)}
                      onBlur={() => {
                        checkStateAndZipcode(form.values.address_data);
                      }}
                    />
                  </div>

                  <ErrorMessage error={errors.stateAndZipCode} />
                </div>

                <InputField
                  color={inputColor}
                  type="text"
                  label="Phone Number"
                  placeholder="(555) 891-4135"
                  name="recipient_data.phone_number"
                  maxLength={16}
                  onChange={(e) =>
                    form.setFieldValue(
                      'recipient_data.phone_number',
                      formatToPhone(e),
                    )
                  }
                  hasError={hasError(errors.phone_number)}
                  onBlur={(e) => {
                    checkPhoneNumber(e.target.value);
                  }}
                />

                <ErrorMessage error={errors.phone_number} />

                <InputField
                  color={inputColor}
                  type="text"
                  label="Contact Person"
                  placeholder="Jane Doe"
                  name="recipient_data.contact_person"
                  onBlur={(e) => {
                    checkContactPerson(e.target.value);
                  }}
                  hasError={hasError(errors.contact)}
                />

                <ErrorMessage error={errors.contact} />

                <div className={styles['fifty-form']}>
                  <InputField
                    type="string"
                    color={InputFieldColor.Primary}
                    label="# Meat Meals"
                    name="meal_request_data.meat_meals"
                    placeholder="Enter number"
                    onKeyDown={(e) =>
                      ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) => {
                      form.setFieldValue(
                        'meal_request_data.meat_meals',
                        e.target.value,
                      );
                      checkMealNum(
                        e.target.value,
                        form.values.meal_request_data.veggie_meals,
                      );
                    }}
                    hasError={hasError(errors.meal_num)}
                    onBlur={() => {
                      const {
                        meat_meals,
                        veggie_meals,
                      } = form.values.meal_request_data;

                      checkMealNum(meat_meals, veggie_meals);
                    }}
                  />
                  <InputField
                    type="string"
                    color={InputFieldColor.Primary}
                    label="# Veggie Meals"
                    name="meal_request_data.veggie_meals"
                    placeholder="Enter number"
                    onKeyDown={(e) =>
                      ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) => {
                      form.setFieldValue(
                        'meal_request_data.veggie_meals',
                        e.target.value,
                      );
                      checkMealNum(
                        e.target.value,
                        form.values.meal_request_data.meat_meals,
                      );
                    }}
                    onBlur={() => {
                      const {
                        meat_meals,
                        veggie_meals,
                      } = form.values.meal_request_data;

                      checkMealNum(meat_meals, veggie_meals);
                    }}
                    hasError={hasError(errors.meal_num)}
                  />
                </div>

                <ErrorMessage error={errors.meal_num} />

                <Label label="When do you need the meals delivered?">
                  <div className={styles.schedule}>
                    <div className={styles['sixty-form']}>
                      <InputField
                        type="date"
                        color={inputColor}
                        name="date"
                        placeholder="yyyy-mm-dd" // TODO: need to change date format
                        onChange={(e) => {
                          deliveryDate.current = e.target.value;
                          setMealTimes(form);
                        }}
                        onBlur={() => {
                          checkDeliveryTime(
                            form.values.meal_request_data.delivery_time,
                          );
                        }}
                        hasError={hasError(errors.delivery_time)}
                      />
                    </div>
                    <div className={styles['forty-form']}>
                      <InputField // TODO: need to change time format
                        type="time"
                        color={inputColor}
                        name="time"
                        onChange={(e) => {
                          deliveryTime.current = e.target.value;
                          setMealTimes(form);
                        }}
                        onBlur={() => {
                          checkDeliveryTime(
                            form.values.meal_request_data.delivery_time,
                          );
                        }}
                        hasError={hasError(errors.delivery_time)}
                      />
                    </div>
                  </div>
                </Label>

                <ErrorMessage error={errors.delivery_time} />

                <InputField
                  color={inputColor}
                  type="text"
                  label="Anything else we should know?"
                  placeholder="Comments or requests"
                  name="meal_request_data.comment"
                />

                <Button
                  theme={buttonTheme}
                  type="submit"
                  onClick={() => {
                    setErrors((prev) => {
                      const state = prev;
                      delete state.address;
                      return { ...state };
                    });

                    setConfirmInfo(form.values);
                  }}
                >
                  {loading ? <Loading isLoading={loading} size={24} /> : 'Next'}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
  return (
    <div className={styles['confirm-container']}>
      <img className={styles.icon} src={Check} alt="check" />
      <HeaderText size={HeaderSize.Default} color={TextColor.Light}>
        Your meal request has <br />
        been received!
      </HeaderText>
      <hr className={styles.line} />
      <div className={styles.infoContainer}>
        <div className={styles.bold}>
          <BodyText size={BodySize.Default} color={TextColor.Light}>
            Delivery Address
          </BodyText>
        </div>
        <BodyText size={BodySize.Default} color={TextColor.Light}>
          {confirmInfo.address_data.address2
            ? `${confirmInfo.address_data.address2} `
            : ''}
          {confirmInfo.address_data.address1}
          <br /> {confirmInfo.address_data.city},{' '}
          {confirmInfo.address_data.state}, {confirmInfo.address_data.zipcode}
        </BodyText>
      </div>
      <div className={styles['info-container']}>
        <div className={styles.bold}>
          <BodyText size={BodySize.Default} color={TextColor.Light}>
            Delivery Date
          </BodyText>
        </div>
        <BodyText size={BodySize.Default} color={TextColor.Light}>
          <DateRenderer date={confirmInfo.meal_request_data.delivery_time} />
        </BodyText>
      </div>
      <hr className={styles.line} />
      <BodyText size={BodySize.Default} color={TextColor.Light}>
        For any issues or questions, <br />
        contact Meal Forward at:
        <br />
        <br />
        <div className={styles.bold}>support@mealforward.org</div>
      </BodyText>
      <div className={styles['button-container']}>
        <div>
          <Button theme={buttonTheme} type="button" onClick={() => setStep(0)}>
            Submit another request
          </Button>
        </div>
        <div>
          <Button
            theme={{ ...buttonTheme, color: ButtonColor.Secondary }}
            type="button"
            onClick={() => history.push('/')}
          >
            Back to home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RequestMealForm;
