import React from 'react';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText';
import { BodyText } from '@app/components/core/Typography/BodyText';
import PublicPageLayout from '@app/components/core/Layout/PublicPageLayout';
import styles from './TermsOfUse.module.scss';

export default function TermsOfUse(): JSX.Element {
  return (
    <PublicPageLayout>
      <div className={styles['terms-of-use-container']}>
        <div className={styles.header}>
          <HeaderText>Terms of Use</HeaderText>
        </div>
        <div className={styles.body}>
          <div className={styles.subtitle}>
            <HeaderText size={HeaderSize.Default}>
              Acceptance of the Terms
            </HeaderText>
          </div>
          <BodyText>
            The following terms of use (these “Terms”) are entered into by and
            between you and Meal Forward (“Meal Forward”, “Company,” “we,” “us”
            or “our”). These Terms govern your access to and use of our website,
            www.mealforward.org (the “Site”) along with any other products or
            services offered by us, whether through the Site or otherwise
            (collectively and together with the Site, the “Services”).
          </BodyText>
          <BodyText>
            Please read these Terms carefully. By accessing, browsing or
            otherwise using the Services or by clicking to accept or agree to
            the Terms when this option is made available to you, you (1)
            acknowledge that you have read and understood these Terms, (2)
            represent and warrant that you meet all of our eligibility
            requirements for using the Services as described in these Terms, and
            (3) accept and agree to be legally bound by these Terms, including
            any other terms applicable to the Services that are incorporated
            herein by reference. If you are using the Services on behalf of an
            entity, you are agreeing to these Terms for that entity and are
            representing to us that you have the authority to legally bind that
            entity to these Terms (in which case “you” will refer to that
            entity). If you do not accept these Terms or do not satisfy the
            eligibility requirements set forth below, you do not use the
            Services.
          </BodyText>
          <div className={styles.subtitle}>
            <HeaderText size={HeaderSize.Default}>Privacy Policy</HeaderText>
          </div>
          <BodyText>
            Your use of, and participation in, the Services offered by the
            Company is subject to the terms set forth in our privacy policy
            located at www.mealforward.org/privacy/ (the “Privacy Policy”). Our
            Privacy Policy details how we collect, use, and share your
            information.
          </BodyText>
          <div className={styles.subtitle}>
            <HeaderText size={HeaderSize.Default}>
              Changes to the Terms of Use
            </HeaderText>
          </div>
          <BodyText>
            We reserve the right to update and revise these Terms at any time.
            We’ll make sure to also change the “Last Updated” date at the top of
            this page so you can tell if these Terms have changed since your
            last visit. Any such changes are effective immediately when we post
            them on the Site and apply to all access to and use of the Services
            thereafter. Please review these Terms regularly because once we post
            any changes, your continued use of the Services constitutes your
            acceptance of the revised Terms.
          </BodyText>
          <div className={styles.subtitle}>
            <HeaderText size={HeaderSize.Default}>Scope of Use</HeaderText>
          </div>
          <BodyText>
            <div className={styles.subtitle}>
              <HeaderText size={HeaderSize.Small}>Donors</HeaderText>
            </div>
            <ul>
              <li>
                Meal Forward provides experiences to visitors of Meal Forward to
                make donations to Beneficiaries.
              </li>
              <li>
                The donation experience may be supported by third party
                software(s) and vendor(s), and information about the donors may
                be collected and used by such provided, per usage outlined in
                the Privacy Policy.
              </li>
            </ul>
            <div className={styles.subtitle}>
              <HeaderText size={HeaderSize.Small}>Beneficiaries</HeaderText>
            </div>
            <ul>
              <li>
                Meal Forward provides experiences to beneficiaries
                (“Beneficiaries”) to make a meal request for their
                organizations.
              </li>
              <li>
                Beneficiaries must make meal requests in good faith, make best
                effort to provide accurate information, and be responsive in
                communicating with Meal Forward in coordinating meal fulfillment
                and resolving issues.
              </li>
              <li>
                Submitting a request for a meal does not guarantee the
                Beneficiary will receive a meal. We will do our best to collect
                funding for as many meals as possible but unfortunately, we
                can’t guarantee a meal for everyone who puts in a request.
              </li>
              <li>
                Meal Forward has rights to cancel or nullify meal requests made
                by Beneficiaries at its sole discretion.
              </li>
            </ul>
            <div className={styles.subtitle}>
              <HeaderText size={HeaderSize.Small}>
                Restaurant Partners
              </HeaderText>
            </div>
            <ul>
              <li>
                In order for restaurants to onboard as a Meal Forward partner,
                restaurants must create a valid account with Meal Forward and
                further agree to the terms of the Master Service Agreement to be
                entered with Meal Forward.
              </li>
              <li>
                Meal Forward has the right to terminate any partnership
                relationship with its restaurant partners at its sole
                discretion.
              </li>
            </ul>
          </BodyText>
          <div className={styles.subtitle}>
            <HeaderText size={HeaderSize.Default}>Indemnification</HeaderText>
          </div>
          <BodyText>
            You agree to defend, indemnify and hold harmless the Company, its
            affiliates, licensors and service providers, and its and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses or fees (including reasonable attorneys&apos; fees) arising
            out of or relating to your violation of these Terms or your use of
            the Services or your use of any information obtained through the
            Services.
          </BodyText>
          <div className={styles.subtitle}>
            <HeaderText size={HeaderSize.Default}>
              Warranty Disclaimers; Limitation of Liability
            </HeaderText>
          </div>
          <BodyText>
            The services are provided “as is” and without warranty of any kind.
            To the maximum extent permitted by law, the Company disclaims all
            representations and warranties, express or implied, relating to the
            Services or any content on the Services, whether provided or owned
            by the Company or by any third party, including without limitation
            warranties of merchantability, fitness for a particular purpose,
            title, non-infringement, freedom from computer virus, and any
            implied warranties arising from course of dealing, course of
            performance, or usage in trade, all of which are expressly
            disclaimed. In addition, you assume total responsibility and risk
            for your use of the Services and the Company does not make any
            representation or warranty that any of the Services or any content
            available through any of the Services is accurate, complete,
            available, current, free of viruses or other harmful components or
            defects, or that the Services will meet your requirements. No advice
            or information, whether oral or written, obtained by you from the
            Company shall create any warranty not expressly made herein. In no
            event whatsoever shall the Company, its affiliates, or suppliers, or
            their respective officers, employees, shareholders, agents, or
            representatives, be liable for any indirect, consequential,
            incidental, special, punitive or exemplary damages, or for any loss
            of profits or revenue, including but not limited to loss of sales,
            profit, revenue, goodwill, or downtime, (arising under tort,
            contract, or other law), regardless of such party’s negligence or
            whether such party knew or should have known of the possibility of
            such damages. You understand and agree that the download of any
            materials in connection with the Services is done at your discretion
            and risk and that you will be solely responsible for any loss or
            damage to your computer system or loss of data that may result from
            the download or upload of any material. The Company neither assumes,
            nor does it authorize any other person to assume on its behalf, any
            other liability in connection with the provision of the Services.
            If, notwithstanding the other provisions of these terms, the Company
            is found to be liable to you for any damage or loss which arises out
            of or is in any way connected with your use of any Services, the
            Company’s liability shall in no event exceed US $100.00. These
            disclaimers and limitations of liability are made to the fullest
            extent permitted by law.
          </BodyText>
          <div className={styles.subtitle}>
            <HeaderText size={HeaderSize.Default}>Termination</HeaderText>
          </div>
          <BodyText>
            We reserve the right, in its sole discretion, to restrict, suspend,
            or terminate these Terms and your access to all or any part of the
            Services, at any time and for any reason without prior notice or
            liability. We reserve the right to change, suspend, or discontinue
            all or any part of the Services at any time without prior notice or
            liability.
          </BodyText>
          <div className={styles.subtitle}>
            <HeaderText size={HeaderSize.Default}>Miscellaneous</HeaderText>
          </div>
          <BodyText>
            In the event that any portion of these Terms is held to be invalid
            or unenforceable, the invalid or unenforceable portion shall be
            construed in accordance with the applicable law as nearly as
            possible to reflect the original intentions of the parties, and the
            remainder of these Terms shall remain in full force and effect. The
            paragraph headings herein are provided only for reference and shall
            have no effect on the construction or interpretation of these Terms.
            Meal Forward&apos;s failure to enforce your strict performance of
            any provision of these Terms will not constitute a waiver of its
            right to subsequently enforce such provision or any other provision
            of these Terms. Neither the course of conduct between parties nor
            trade practice shall act to modify any provision of these Terms.
            These Terms of Use shall be governed by and construed in accordance
            with the laws of the State of California, except with regard to its
            conflicts of law rules. Any cause of action you may have with
            respect to the Services or these Terms must be commenced within one
            (1) year after the claim or cause of action arose, or be barred.
            These Terms contain the entire agreement of the parties for the
            Services and supersedes all existing agreements and all other oral,
            written or other communication between the parties concerning its
            subject matter. You agree to use the Services only for lawful
            purposes. You are prohibited from any use of the Services that would
            constitute an illegal offense, give rise to liability or otherwise
            violate any applicable local, state, national or international law
            or regulation.
          </BodyText>
        </div>
      </div>
    </PublicPageLayout>
  );
}
