import { gql } from '@apollo/client';

import ClaimDetailsFragment from '@app/views/restaurants/myOrders/graphql/fragments/ClaimDetailsFragment.graphql';

export default gql`
  ${ClaimDetailsFragment}
  query GetClaimsByRestaurantIDQuery($restaurantID: Int!) {
    outstandingClaims: claims_by_restaurant_id(
      id: $restaurantID
      states: ["pending", "confirmed", "delivered"]
    ) {
      ...ClaimDetailsFragment
    }
    fulfilledClaims: claims_by_restaurant_id(
      id: $restaurantID
      states: ["fulfilled"]
    ) {
      ...ClaimDetailsFragment
    }
    cancelledClaims: claims_by_restaurant_id(
      id: $restaurantID
      states: ["cancelled"]
    ) {
      ...ClaimDetailsFragment
    }
  }
`;
