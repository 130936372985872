import React from 'react';
import Logo from '@app/components/core/Logo';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText';
import { BodyText, BodySize } from '@app/components/core/Typography/BodyText';
import {
  ProgressBar,
  ProgressBarColor,
} from '@app/components/core/ProgressBar/index';
import styles from './SignUpDesc.module.scss';

interface SignUpProps {
  barIdx: number;
}

export const SignUpDesc: React.FC<SignUpProps> = ({ barIdx }: SignUpProps) => {
  let text = null;

  if (barIdx === 0)
    text = (
      <>
        <HeaderText size={HeaderSize.Default}>
          Register your restaurant on Meal Forward and start claiming orders
        </HeaderText>
        <br />
        <BodyText size={BodySize.Default}>
          Sign up to claim orders from local hospitals, shelters, and other
          organizations.
        </BodyText>
      </>
    );
  else
    text = (
      <>
        <HeaderText size={HeaderSize.Default}>Create your account </HeaderText>
        <br />
        <BodyText size={BodySize.Default}>
          This is the account you’ll sign into when you claim orders for your
          restaurant or check for updates.
        </BodyText>
      </>
    );
  return (
    <div className={styles['sign-up-desc-wrapper']}>
      <Logo isWithUnderBar />
      <div className={styles['bar-container']}>
        <ProgressBar
          stepIndex={barIdx}
          totalSteps={2}
          color={ProgressBarColor.Primary}
        />
      </div>
      {text}
    </div>
  );
};

export default SignUpDesc;
