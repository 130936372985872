import React from 'react';
import styles from './Button.module.scss';

export enum ButtonColor {
  Primary = 'color-primary',
  Secondary = 'color-secondary',
}

export enum ButtonSize {
  Small = 'size-small',
  Default = 'size-default',
  Large = 'size-large',
}

export enum ButtonWidth {
  Compact = 'width-compact',
  Stretch = 'width-stretch',
}

interface IButtonTheme {
  color: ButtonColor;
  size: ButtonSize;
  width: ButtonWidth;
}

export interface IButtonProps {
  type: 'button' | 'submit' | 'reset';
  verticalPadding?: number;
  theme: IButtonTheme;
  onClick?: React.MouseEventHandler;
  iconSrc?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

/**
 * Renders a button with text and optionally, an icon.
 */
export const Button: React.FC<IButtonProps> = ({
  type,
  verticalPadding,
  theme,
  onClick,
  iconSrc,
  disabled,
  children,
}: IButtonProps) => {
  const disabledClassName = disabled ? styles.disabled : '';
  const themeClassName = `${styles[theme.color]} ${styles[theme.size]} ${
    styles[theme.width]
  } ${disabledClassName}`;

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={themeClassName}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      style={
        verticalPadding
          ? { paddingLeft: verticalPadding, paddingRight: verticalPadding }
          : {}
      }
    >
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      {iconSrc && <img src={iconSrc} />}
      {children}
    </button>
  );
};
Button.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
};

interface IIconButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

/**
 * Renders a button with an icon.
 */
export const IconButton: React.FC<IIconButtonProps> = ({
  onClick,
  children,
}: // eslint-disable-next-line react/button-has-type
IIconButtonProps) => <button onClick={onClick}>{children}</button>;
