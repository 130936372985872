import React from 'react';
import LoginForm from '@app/views/login/components/loginForm/index';
import LoginDesc from '@app/views/login/components/loginDesc/index';
import MFFiftyFiftyLayout from '@app/components/core/Layout/MFFiftyFiftyLayout/index';

export default function Login(): JSX.Element {
  return (
    <MFFiftyFiftyLayout
      leftColumn={<LoginDesc />}
      rightColumn={<LoginForm />}
    />
  );
}
