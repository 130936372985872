import React from 'react';
import './styles.scss';
import '../styles.scss';

import { TextColor, TextWeight } from '..';

export enum HeaderSize {
  Large = 'size-header-large',
  Default = 'size-header-default',
  Small = 'size-header-small',
  XSmall = 'size-header-xsmall',
}

export interface HeaderTextProps {
  color?: TextColor;
  size?: HeaderSize;
  children?: React.ReactNode;
}

const defaultProps: HeaderTextProps = {
  color: TextColor.Dark,
  size: HeaderSize.Default,
};

export const HeaderText: React.FC<HeaderTextProps> = ({
  color,
  size,
  children,
}: HeaderTextProps) => {
  const themeClassName = `text ${color} ${size} ${TextWeight.Bold}`;

  return <div className={themeClassName}>{children}</div>;
};

HeaderText.defaultProps = defaultProps;

export default HeaderText;
