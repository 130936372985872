/* eslint react/prop-types: 0 */
import React from 'react';
import styles from './Label.module.scss';

export interface LabelProps {
  label: React.ReactElement | string;
  htmlFor?: string;
}

export const Label: React.FC<LabelProps> = ({ label, htmlFor, children }) => {
  return (
    <div className={styles.root}>
      <label htmlFor={htmlFor}>{label}</label>
      {children}
    </div>
  );
};
