import { gql } from '@apollo/client';

export default gql`
  fragment ClaimDetailsFragment on ClaimType {
    id
    tax_percentage
    unit_price_meat
    unit_price_vegetarian
    invoice {
      meat_subtotal
      vegetarian_subtotal
      total
    }
    current_state {
      state
    }
    request {
      id
      num_meat_meals
      num_vegetarian_meals
      delivery_date_of_request {
        completed_at
        expected_delivery_at
        address {
          address_line_1
          address_line_2
          state
          city
          country
          postal_code
        }
      }
      recipient {
        id
        name
      }
    }
  }
`;
