import { TextColor } from 'components/core/Typography';
import { BodySize, BodyText } from 'components/core/Typography/BodyText';
import React, { useState } from 'react';
import InvoiceForm, {
  InvoiceFormData,
} from '@app/views/restaurants/invoice/components/InvoiceForm';

import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button';
import styles from './InvoiceFormStep.module.scss';
import formStyles from '../../MealRequestInvoiceForm.module.scss';

export interface InvoiceFormStepProps {
  initialValues: InvoiceFormData;
  onSuccess: (values: InvoiceFormData) => void;
}

const InvoiceFormStep: React.FC<InvoiceFormStepProps> = ({
  initialValues,
  onSuccess,
}: InvoiceFormStepProps) => {
  const [canContinue, setCanContinue] = useState(true);
  return (
    <div>
      <div className={formStyles.section}>
        <div className={formStyles['form-title']}>
          <BodyText color={TextColor.Light} size={BodySize.Small}>
            <span className={formStyles.bolded}>Order Price</span>
          </BodyText>
        </div>
        <div>
          <BodyText color={TextColor.Light} size={BodySize.Small}>
            This is the amount you will be paid for completing this order. You
            can edit meal prices and service fee.
          </BodyText>
        </div>
      </div>
      <div className={styles['invoice-form']}>
        <InvoiceForm
          initialValues={initialValues}
          onSubmit={onSuccess}
          onValidate={(valid) => setCanContinue(valid)}
          footer={
            <div className={formStyles.buttons}>
              <Button
                type="submit"
                disabled={!canContinue}
                verticalPadding={36}
                theme={{
                  color: ButtonColor.Primary,
                  size: ButtonSize.Small,
                  width: ButtonWidth.Compact,
                }}
              >
                Next
              </Button>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default InvoiceFormStep;
