import React from 'react';
import { useMealForwardFundsRaisedMetric } from '@app/hooks/useMealForwardFundsRaisedMetric';
import { useMealForwardMealsDeliveredMetric } from '@app/hooks/useMealForwardMealsDeliveredMetric';

import Grid from '@material-ui/core/Grid';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText';
import { BodyText, BodySize } from '@app/components/core/Typography/BodyText';
import { TextColor, TextWeight } from '@app/components/core/Typography';

import styles from './OurImpact.module.scss';

const OurImpact: React.FC = () => {
  const fundsRaised = useMealForwardFundsRaisedMetric({
    fallback: '230,000',
  });
  const mealsDelivered = useMealForwardMealsDeliveredMetric({
    fallback: '17,000+',
  });
  return (
    <Grid container className={styles['our-impact-wrapper']}>
      <Grid container style={{ paddingBottom: 8 }}>
        <BodyText color={TextColor.Purple} weight={TextWeight.Bold}>
          OUR IMPACT
        </BodyText>
      </Grid>
      <Grid
        container
        item
        sm={12}
        md={6}
        className={styles['our-impact-container-1']}
      >
        <Grid container className={styles['text-large']}>
          <HeaderText size={HeaderSize.Large}>
            {`We connect three sides of a
              complicated network
              to deliver quality
              food to those most in need.`}
          </HeaderText>
        </Grid>
      </Grid>
      <Grid
        container
        item
        sm={12}
        md={6}
        className={styles['our-impact-container-2']}
      >
        <Grid container className={styles.box}>
          <Grid container className={styles['text-large']}>
            <HeaderText size={HeaderSize.Large} color={TextColor.Purple}>
              ${fundsRaised}+ raised
            </HeaderText>
          </Grid>
          <BodyText size={BodySize.Large}>
            We’re thankful for the funds we’ve raised through private donations,
            corporate matching, and fundraisers
          </BodyText>
          <BodyText size={BodySize.Large} weight={TextWeight.Bold}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/spreadsheets/d/1Wb-jjWkCv2kAz2xgpQw7vEZpv16CUJwS9ex9pHu5JQA/edit#gid=0"
            >
              Learn more
            </a>
          </BodyText>
        </Grid>
        <Grid container className={styles.box}>
          <Grid container className={styles['text-large']}>
            <HeaderText size={HeaderSize.Large} color={TextColor.Purple}>
              {mealsDelivered} meals delivered
            </HeaderText>
          </Grid>
          <BodyText size={BodySize.Large}>
            We started small, donating a few hundred meals in the hospitals in
            Santa Clara and Fremont, CA in April 2020. We’re proud to say we’ve
            donated thousands of meals since then, and continue to expand our
            efforts across the Bay Area and Texas. Our plan is to expand
            nationally soon. <br />
          </BodyText>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurImpact;
