import React from 'react';

export interface DateRendererProps {
  date: Date;
}

/**
 * DateRenderer formats address components into
 * the following format: 2:00pm on January 1, 2021
 */
const DateRenderer: React.FC<DateRendererProps> = ({
  date: inputDate,
}: DateRendererProps) => {
  const date = new Date(inputDate);
  const hourMod12 = date.getHours() % 12;
  const hour = hourMod12 === 0 ? 12 : hourMod12;
  const amOrPm = Math.floor(date.getHours() / 12) === 1 ? 'pm' : 'am';
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  const dateTime = `${hour}:${minutes}${amOrPm} on ${month} ${day}, ${year}`;

  return <span>{dateTime}</span>;
};

export default DateRenderer;
