import React, { useCallback, useState } from 'react';

import { Sidebar, SidebarProps } from '@app/components/core/Layout/Sidebar';

import styles from './useSidebar.module.scss';

interface useSidebarControls {
  Sidebar: React.FC<SidebarProps>;
  openSidebar: () => void;
  closeSidebar: () => void;
}

export interface useSidebarProps {
  onClose?: () => void;
  outsideClickClosesSidebar: boolean;
}

/**
 * useSidebar is a hook that exposes the Sidebar API for showing a sidebar
 * and having a hook for when the sidebar is manually closed by the user.
 *
 * useSidebar takes one argument that allows you to configure the
 *   onClose behavior and whether to listen to clicks outside the sidebar as
 *   an alternative way to close the sidebar
 *
 * useSidebar returns three utilities:
 *   - <Sidebar> with props for the title and body in the sidebar view
 *   - openSidebar() to signal when you want to open the Sidebar
 *   - closeSidebar() to signal whenever you want to close the Sidebar
 */
export const useSidebar = (props: useSidebarProps): useSidebarControls => {
  const { onClose, outsideClickClosesSidebar } = props;
  const [isInit, setIsInit] = useState(true);
  const [visible, setVisible] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(true);

  const openSidebar = useCallback(() => {
    setIsInit(true);
    setVisible(true);
  }, [setVisible]);

  const closeSidebar = useCallback(() => {
    if (onClose != null) {
      onClose();
    }
    setAnimationComplete(false);
    setVisible(false);
  }, [onClose, setAnimationComplete, setVisible]);

  const onAnimationComplete = useCallback(() => {
    setAnimationComplete(true);
    setIsInit(false);
  }, [setAnimationComplete]);

  const SidebarWrapper: React.FC<SidebarProps> = (
    sidebarProps: SidebarProps,
  ) => {
    if (!visible && animationComplete) {
      return null;
    }

    return (
      <div className={styles['sidebar-container']}>
        <div className={styles['sidebar-wrapper']}>
          <Sidebar
            {...sidebarProps}
            isInit={isInit}
            visible={visible}
            onClose={closeSidebar}
            onAnimationComplete={onAnimationComplete}
            outsideClickClosesSidebar={outsideClickClosesSidebar}
          />
        </div>
      </div>
    );
  };

  return { Sidebar: SidebarWrapper, openSidebar, closeSidebar };
};

export default useSidebar;
