import React from 'react';
import Logo from '@app/components/core/Logo';
import Grid from '@material-ui/core/Grid';
import HeaderButton from './HeaderButton';

import styles from './Header.module.scss';

const Header: React.FC = () => {
  return (
    <Grid container className={styles['header-container']}>
      <Logo />
      <HeaderButton />
    </Grid>
  );
};

export default Header;
