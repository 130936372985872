import React from 'react';

import styles from './DismissButton.module.scss';

export interface DismissButtonProps {
  size?: 24 | 40;
  onPress?: () => void;
  ariaLabel: string;
}

/**
 * DismissButton provides the standard Meal Forward dismiss button design
 */
export const DismissButton: React.FC<DismissButtonProps> = ({
  onPress,
  ariaLabel,
  size = 40,
}: DismissButtonProps) => {
  const buttonSizeStyle = {
    width: size,
    height: size,
    borderRadius: size / 2,
    backgroundSize: size === 24 ? 12 : 16,
  };
  return (
    <div
      className={styles['close-button']}
      onClick={onPress}
      onKeyPress={onPress}
      role="button"
      tabIndex={0}
      aria-label={ariaLabel}
      style={buttonSizeStyle}
    />
  );
};

export default DismissButton;
