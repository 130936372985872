import { gql } from '@apollo/client';

export default gql`
  query AvailableRequestsQuery {
    available_requests {
      id
      num_meat_meals
      num_vegetarian_meals
      delivery_date_of_request {
        expected_delivery_at
      }
      recipient {
        name
        address {
          address_line_1
          address_line_2
          city
          state
          postal_code
        }
      }
    }
  }
`;
