import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button/index';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText';
import { BodyText, BodySize } from '@app/components/core/Typography/BodyText';
import { TextColor, TextWeight } from '@app/components/core/Typography';
import BoxIcon from '../images/box-icon.svg';
import ProfileIcon from '../images/profile-icon.svg';
import StarIcon from '../images/star-icon.svg';

import styles from './HowItWorks.module.scss';

const HowItWorks: React.FC = () => {
  const history = useHistory();
  const buttonTheme = {
    color: ButtonColor.Secondary,
    size: ButtonSize.Default,
    width: ButtonWidth.Compact,
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  interface Props {
    children: React.ReactNode;
  }
  const Box: React.FC<Props> = ({ children }: Props) => (
    <Grid
      container
      item
      justify="flex-start"
      alignItems={mobile ? 'flex-start' : 'center'}
      direction="column"
      sm={12}
      md={4}
      className={styles.box}
    >
      {children}
    </Grid>
  );
  const IconContainer: React.FC<Props> = ({ children }: Props) => (
    <Grid
      container
      item
      justify="center"
      alignItems="center"
      className={styles['icon-container']}
    >
      {children}
    </Grid>
  );

  return (
    <Grid container justify="center" className={styles['how-it-works-wrapper']}>
      <Grid container item direction="column" xs={11} md={9}>
        <Grid
          container
          item
          justify={mobile ? 'flex-start' : 'center'}
          style={{ paddingBottom: 8 }}
        >
          <BodyText color={TextColor.Purple} weight={TextWeight.Bold}>
            HOW IT WORKS
          </BodyText>
        </Grid>
        <Grid
          container
          item
          justify={mobile ? 'flex-start' : 'center'}
          className={styles['text-large']}
        >
          <HeaderText size={HeaderSize.Large}>How we do it</HeaderText>
          <BodyText size={BodySize.Large}>
            We receive money from donors who want to provide meals to the
            community. We use that money to buy meals from local restaurants,
            and the restaurants deliver the meals to frontline workers and
            people in need.
          </BodyText>
        </Grid>
      </Grid>

      <Grid container className={styles.boxes}>
        <Box>
          <IconContainer>
            <img src={BoxIcon} alt="box" />
          </IconContainer>
          <HeaderText size={HeaderSize.Default}>Donors</HeaderText>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
            className={styles['sub-box']}
          >
            <BodyText>
              No donation is too small to help people in need. We also work with
              corporations and other organizations who want to make larger
              donations. Find out how your donation can make a difference.
            </BodyText>
            <Button
              type="button"
              theme={buttonTheme}
              onClick={() => {
                window.open(
                  'https://give.cornerstone.cc/mealforward',
                  '_blank',
                );
              }}
            >
              Donate Now
            </Button>
          </Grid>
        </Box>
        <Box>
          <IconContainer>
            <img src={ProfileIcon} alt="profile" />
          </IconContainer>
          <HeaderText size={HeaderSize.Default}>Restaurants</HeaderText>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
            className={styles['sub-box']}
          >
            <BodyText>
              We want to help local restaurants by creating new opportunities
              for your business. We’ll collect orders and you decide which ones
              you want to claim and fulfill.
            </BodyText>
            <Button
              type="button"
              theme={buttonTheme}
              onClick={() => history.push('/restaurant')}
            >
              Claim an Order
            </Button>
          </Grid>
        </Box>
        <Box>
          <IconContainer>
            <img src={StarIcon} alt="star" />
          </IconContainer>
          <HeaderText size={HeaderSize.Default}>Beneficiaries</HeaderText>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="space-between"
            className={styles['sub-box']}
          >
            <BodyText>
              COVID-19 increased the demand for meals to healthcare workers,
              along with vulnerable populations, including seniors. If your
              organization is in need of meals, place a request.
            </BodyText>
            <Button
              type="button"
              theme={buttonTheme}
              onClick={() => history.push('/mealrequest')}
            >
              Request Meals
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HowItWorks;
