/* eslint-disable camelcase */
import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';

import { TextColor } from 'components/core/Typography';
import HeaderText, { HeaderSize } from 'components/core/Typography/HeaderText';
import { BodyText, BodySize } from 'components/core/Typography/BodyText';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { ClaimsOfRestaurantProps } from 'views/restaurants/myOrders/components/basic-component';
import GET_CLAIMS_BY_RESTAURANT_ID_QUERY from 'views/restaurants/myOrders/graphql/GetClaimsByRestaurantIDQuery';
import {
  GetClaimsByRestaurantIDQuery,
  GetClaimsByRestaurantIDQuery_cancelledClaims,
  GetClaimsByRestaurantIDQuery_fulfilledClaims,
  GetClaimsByRestaurantIDQuery_outstandingClaims,
} from 'views/restaurants/myOrders/graphql/types/GetClaimsByRestaurantIDQuery';
import MyOrderNullState from 'views/restaurants/myOrders/MyOrderNullState';
import UserContext from 'UserContext';
import TableWithTitle from 'views/restaurants/myOrders/components/utilized-component/TableWithTitle';
import styles from './TableList.module.scss';

export interface TableListProps {
  title: string;
}

interface RestaurantClaims {
  outstandingClaims: ClaimsOfRestaurantProps[];
  fulfilledClaims: ClaimsOfRestaurantProps[];
  cancelledClaims: ClaimsOfRestaurantProps[];
}

type unionClaimType =
  | GetClaimsByRestaurantIDQuery_outstandingClaims
  | GetClaimsByRestaurantIDQuery_fulfilledClaims
  | GetClaimsByRestaurantIDQuery_cancelledClaims;
const prepareTableInfoData = (
  claims: unionClaimType[],
): ClaimsOfRestaurantProps[] => {
  return (
    claims.map((claim) => {
      const delivery =
        claim.request?.delivery_date_of_request?.expected_delivery_at;
      const meatMeals = claim.request?.num_meat_meals || 0;
      const vegeMeals = claim.request?.num_vegetarian_meals || 0;
      const totalNumMeals = meatMeals + vegeMeals || 0;
      const total = claim.invoice?.total || 0;

      const requestId = claim.request?.id;
      const currentState = claim.current_state?.state;
      const beneficiaryName = claim.request?.recipient.name;
      if (
        requestId == null ||
        currentState == null ||
        beneficiaryName == null
      ) {
        return null;
      }

      return {
        id: Number(claim.id),
        currentState,
        total,
        request: {
          id: requestId,
          requestTime: new Date(delivery),
          totalNumMeals,
          beneficiaryName,
        },
        taxPercentage: claim.tax_percentage,
      };
    }) || []
  ).filter((item): item is ClaimsOfRestaurantProps => item != null);
};

const TableList: React.FC = () => {
  const { user } = useContext(UserContext);

  const [
    restaurantClaims,
    setRestaurantClaims,
  ] = useState<RestaurantClaims | null>(null);

  /** Query to get the restaurant's claims */
  const { data: _data } = useQuery<GetClaimsByRestaurantIDQuery>(
    GET_CLAIMS_BY_RESTAURANT_ID_QUERY,
    {
      onCompleted: (data) => {
        if (data == null) {
          return;
        }
        const { outstandingClaims, fulfilledClaims, cancelledClaims } = data;
        if (
          outstandingClaims == null ||
          fulfilledClaims == null ||
          cancelledClaims == null
        ) {
          return;
        }

        setRestaurantClaims({
          outstandingClaims: prepareTableInfoData(outstandingClaims),
          fulfilledClaims: prepareTableInfoData(fulfilledClaims),
          cancelledClaims: prepareTableInfoData(cancelledClaims),
        });
      },
      variables: {
        restaurantID: user?.restaurantId,
      },
    },
  );

  if (
    !restaurantClaims?.outstandingClaims.length &&
    !restaurantClaims?.fulfilledClaims.length &&
    !restaurantClaims?.cancelledClaims.length
  ) {
    return <MyOrderNullState />;
  }

  return (
    <>
      {restaurantClaims.outstandingClaims.length !== 0 ? (
        <TableWithTitle
          title="Outstanding Orders"
          claims={restaurantClaims.outstandingClaims}
          totalAndStateToggle={false}
        />
      ) : null}
      {restaurantClaims.fulfilledClaims.length !== 0 ? (
        <TableWithTitle
          title="Completed & Paid"
          claims={restaurantClaims.fulfilledClaims}
          totalAndStateToggle
        />
      ) : null}
      {restaurantClaims?.cancelledClaims.length !== 0 ? (
        <TableWithTitle
          title="Canceled"
          claims={restaurantClaims?.cancelledClaims}
          totalAndStateToggle
        />
      ) : null}
      <Footer />
    </>
  );
};
const Footer: React.FC = () => {
  return (
    <>
      <div className={styles.devider} />
      <div className={styles['footer-padding']}>
        <HeaderText size={HeaderSize.Small}>Need some help?</HeaderText>
        <BodyText size={BodySize.Small}>
          You can contact MealForward at:
        </BodyText>
        <br />
        <HeaderText color={TextColor.Purple} size={HeaderSize.XSmall}>
          <a
            href="mailto:support@mealforward.org"
            className={styles['footer-link']}
          >
            support@mealforward.org
          </a>
        </HeaderText>
      </div>
    </>
  );
};
export default TableList;
