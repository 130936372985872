import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import BasicTable, {
  ClaimsOfRestaurantProps,
} from 'views/restaurants/myOrders/components/basic-component';
import TableTitle from 'views/restaurants/myOrders/components/basic-component/TableTitle';
import styles from './TableWithTitle.module.scss';

interface TableWithTitleProps {
  title: string;
  claims: ClaimsOfRestaurantProps[];
  totalAndStateToggle: boolean;
}

const TableWithTitle: React.FC<TableWithTitleProps> = ({
  title,
  claims,
  totalAndStateToggle,
}: TableWithTitleProps) => {
  return (
    <div className={styles['margin-bottom']}>
      <TableTitle title={title} />
      <div className={styles['spacer-height']} />
      <BasicTable claims={claims} totalAndStateToggle={totalAndStateToggle} />
    </div>
  );
};

export default TableWithTitle;
