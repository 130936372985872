import React from 'react';
import { AppPath, getAppPathConfig } from '@app/paths';
import { Link } from '@app/components/core/Link';
import { BodyText } from '@app/components/core/Typography/BodyText';
import { TextWeight } from '@app/components/core/Typography';
import DismissButton from '@app/components/core/Interaction/DismissButton';
import styles from './OrderPendingToast.module.scss';

export interface OrderPendingToastProps {
  onDismiss: () => void;
}

export const OrderPendingToast: React.FC<OrderPendingToastProps> = ({
  onDismiss,
}: OrderPendingToastProps) => (
  <div className={styles.container}>
    <div className={styles.inline}>
      <BodyText>
        Order is pending and has been added to&nbsp;
        <BodyText weight={TextWeight.Bold}>
          <Link
            to={getAppPathConfig(AppPath.MY_ORDERS).uri}
            className={styles.link}
          >
            My Orders
          </Link>
        </BodyText>
        .
      </BodyText>

      <div className={styles['dismiss-button']}>
        <DismissButton onPress={onDismiss} size={40} ariaLabel="Dismiss" />
      </div>
    </div>
  </div>
);

export default OrderPendingToast;
