import React from 'react';
import { Link } from 'react-router-dom';
import { AppPath, getAppPathConfig } from '@app/paths';
import {
  BodyText,
  BodySize,
} from '@app/components/core/Typography/BodyText/index';
import Logo from '@app/components/core/Logo';
import Grid from '@material-ui/core/Grid';
import styles from './Footer.module.scss';
import logo from '../images/old-logo-dark.svg';
import twitter from '../images/Icon-twitter.png';
import fb from '../images/Icon-fb.png';
import insta from '../images/Icon-insta.png';

const Footer: React.FC = () => {
  return (
    <Grid container justify="center">
      <Grid container item className={styles['footer-container']}>
        <Grid
          container
          item
          direction="column"
          alignItems="flex-start"
          sm={12}
          md={8}
        >
          <div className={styles['logo-wrapper']}>
            <Logo />
          </div>
          <div className={styles['footer-content']}>
            <BodyText size={BodySize.Default}>
              Meal Forward is operating through a CA non-profit public benefit
              corporation using fiscal sponsorship with Players Philanthropy
              Fund (Federal Tax ID: 27-6601178), a Maryland charitable trust
              with federal tax-exempt status as a public charity under Section
              501(c)(3) of the Internal Revenue Code.
              <br />
              <br />
              Contributions to Meal Forward are tax-deductible to the fullest
              extent of the law.
              <br />
              <br />© 2020 - All rights reserved
            </BodyText>
          </div>
          <Grid
            container
            item
            direction="row"
            className={styles['social-btn-container']}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.twitter.com/meal_forward"
            >
              <div>
                <img src={twitter} alt="twitter" />
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/mealforward.org/"
            >
              <div>
                <img src={fb} alt="fb" />
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/meal_forward/"
            >
              <div>
                <img src={insta} alt="insta" />
              </div>
            </a>
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={12}
          md={4}
          direction="column"
          className={styles.links}
        >
          <Link to={getAppPathConfig(AppPath.PRIVACY_POLICY).uri}>
            Privacy Policy
          </Link>
          <Link to={getAppPathConfig(AppPath.TERMS_OF_USE).uri}>
            Terms of Use
          </Link>
          <Link to={getAppPathConfig(AppPath.OPEN_SOURCE).uri}>
            Open Source
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
