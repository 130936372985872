import React from 'react';
import { Link as ReactLink, LinkProps } from 'react-router-dom';

import styles from './Link.module.scss';

/**
 * Link provides a thin wrapper around React router Link and allows us
 * to include analytics or default styling we want enforced across the
 * application.
 */
export const Link: React.FC<LinkProps> = ({
  className,
  ...props
}: LinkProps) => {
  return (
    <ReactLink {...props} className={[className, styles.link].join(' ')} />
  );
};

export default Link;
