import React, { useCallback, useState, useMemo } from 'react';
import { Avatar, Box, ListItem } from '@material-ui/core';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from 'components/core/Interaction/Button';
import { HeaderText, HeaderSize } from 'components/core/Typography/HeaderText';
import { BodyText, BodySize } from 'components/core/Typography/BodyText';
import useMealRequestInvoiceForm from 'views/restaurants/invoice/hooks/useMealRequestInvoiceForm';
import { OrderPendingToast } from '@app/views/restaurants/myOrders/components/OrderPendingToast';
import DateRenderer from './core/DateRenderer';
import { DismissButton } from './core/Interaction/DismissButton';

import styles from './MealRequest.module.scss';

export type MealRequest = {
  id: string;
  avatarSrc?: string;
  requestTime: Date;
  beneficiaryName: string;
  beneficiaryAddress: string;
  claimAmount?: number;
  numMealsRequested: number;
  specialRequests: {
    numVegetarian: number;
  };
};

interface IBasicMealRequestProps {
  restaurantId: string;
  mealRequest: MealRequest;
}

enum MealRequestRowState {
  Pending,
  Focused,
  Completed,
  Dismissed,
}

export const BasicMealRequest: React.FC<IBasicMealRequestProps> = ({
  restaurantId,
  mealRequest,
}: IBasicMealRequestProps) => {
  const [rowState, setRowState] = useState<MealRequestRowState>(
    MealRequestRowState.Pending,
  );
  const {
    mealRequestInvoiceForm,
    openMealRequestInvoiceForm,
    closeMealRequestInvoiceForm,
  } = useMealRequestInvoiceForm({
    requestID: mealRequest.id,
    restaurantID: restaurantId,
    onClose: () => setRowState(MealRequestRowState.Pending),
    onSuccess: () => setRowState(MealRequestRowState.Completed),
    outsideClickClosesSidebar: true,
  });

  const onRequestButtonClick = useCallback(() => {
    if (rowState === MealRequestRowState.Focused) {
      closeMealRequestInvoiceForm();
      setRowState(MealRequestRowState.Pending);
    } else {
      openMealRequestInvoiceForm();
      setRowState(MealRequestRowState.Focused);
    }
  }, [rowState, closeMealRequestInvoiceForm, openMealRequestInvoiceForm]);

  const requestButton = useMemo(() => {
    const body =
      rowState === MealRequestRowState.Focused ? (
        <>
          <DismissButton size={24} ariaLabel="close" />
          Cancel
        </>
      ) : (
        `View Detail`
      );
    return (
      <MealRequestButton onClick={onRequestButtonClick}>
        {body}
      </MealRequestButton>
    );
  }, [onRequestButtonClick, rowState]);

  if (rowState === MealRequestRowState.Dismissed) {
    return null;
  }

  if (rowState === MealRequestRowState.Completed) {
    return (
      <OrderPendingToast
        onDismiss={() => {
          setRowState(MealRequestRowState.Dismissed);
        }}
      />
    );
  }

  return (
    <>
      <ListItem
        className={`${styles['list-item']} ${
          rowState === MealRequestRowState.Focused
            ? styles['unblurred-list']
            : ''
        }`}
      >
        <div className={styles.row}>
          <NumberOfMeals
            numMeals={
              mealRequest.numMealsRequested +
              mealRequest.specialRequests.numVegetarian
            }
          />
          <MealRequestDetails
            beneficiary={mealRequest.beneficiaryName}
            address={mealRequest.beneficiaryAddress}
            requestedTime={mealRequest.requestTime}
          />
        </div>
        {requestButton}
      </ListItem>
      <div className={styles.form}>{mealRequestInvoiceForm}</div>
    </>
  );
};

interface IDetailedMealRequestProps {
  mealRequest: MealRequest;
  onClick: () => void;
}

export const DetailedMealRequest: React.FC<IDetailedMealRequestProps> = ({
  mealRequest,
  onClick,
}: IDetailedMealRequestProps) => {
  const { specialRequests } = mealRequest;
  const specialRequestText = `${specialRequests.numVegetarian} vegetarian`;

  return (
    <ListItem className={styles['list-item']}>
      <div className={styles.row}>
        <MealRequestAvatar src={mealRequest.avatarSrc} />
        <div>
          <MealRequestDetails
            beneficiary={mealRequest.beneficiaryName}
            address={mealRequest.beneficiaryAddress}
            requestedTime={mealRequest.requestTime}
          />
          <br />
          <BodyText size={BodySize.Small}>{specialRequestText}</BodyText>
        </div>
      </div>
      <div className={styles.column}>
        {mealRequest.claimAmount && (
          <Box m="auto">
            <HeaderText size={HeaderSize.Small}>
              ${mealRequest.claimAmount.toLocaleString()}
            </HeaderText>
          </Box>
        )}
        <MealRequestButton onClick={onClick}>Claim</MealRequestButton>
      </div>
    </ListItem>
  );
};

interface INumberOfMealsProps {
  numMeals: number;
}

const NumberOfMeals: React.FC<INumberOfMealsProps> = ({
  numMeals,
}: INumberOfMealsProps) => {
  return (
    <Box
      m="auto"
      alignItems="center"
      textAlign="center"
      className={styles['left-box']}
    >
      <HeaderText size={HeaderSize.Default}>{numMeals}</HeaderText>
      <BodyText size={BodySize.Small}>Meals</BodyText>
    </Box>
  );
};

interface IMealRequestDetailsProps {
  beneficiary: string;
  address: string;
  requestedTime: Date;
}

const MealRequestDetails: React.FC<IMealRequestDetailsProps> = ({
  beneficiary,
  address,
  requestedTime,
}: IMealRequestDetailsProps) => {
  return (
    <BodyText size={BodySize.Small}>
      {beneficiary}
      <br />
      {address}
      <br />
      <DateRenderer date={requestedTime} />
    </BodyText>
  );
};

interface IMealRequestButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const MealRequestButton: React.FC<IMealRequestButtonProps> = ({
  onClick,
  children,
}: IMealRequestButtonProps) => {
  const buttonTheme = {
    color: ButtonColor.Secondary,
    size: ButtonSize.Small,
    width: ButtonWidth.Compact,
  };

  return (
    <Button type="button" theme={buttonTheme} onClick={onClick}>
      {children}
    </Button>
  );
};

interface IMealRequestAvatar {
  src: string | undefined;
}

const MealRequestAvatar: React.FC<IMealRequestAvatar> = ({
  src,
}: IMealRequestAvatar) => {
  return <Avatar className={styles['left-box']} variant="rounded" src={src} />;
};
