import { useState } from 'react';

interface useMyOrderListControls {
  isSidebarOpened: boolean;
  addZindex: () => void;
  removeZindex: () => void;
}

/**
 * useMyOrderList is a hook that controls the blurriness of a order
 * of which button clicked.
 *
 * useMyOrderList returns 4 utilities:
 *   - isSidebarOpened to decide if sidebar is opened or not.
 *   - addZindex() to add z-index of clicked order in order to unblur it.
 *   - removeZindex() to remove z-index whenever user closes the Sidebar.
 */

export const useMyOrderList = (): useMyOrderListControls => {
  const [isSidebarOpened, setIsSidebarOpened] = useState<boolean>(false);

  const addZindex = () => {
    setIsSidebarOpened(true);
  };

  const removeZindex = () => {
    setIsSidebarOpened(false);
  };
  return {
    isSidebarOpened,
    addZindex,
    removeZindex,
  };
};

export default useMyOrderList;
