import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { AppPath, getAppPathConfig } from '@app/paths';
import { Link } from 'react-router-dom';
import { validateEmail } from '@app/components/core/Validation';
import { TextColor } from '@app/components/core/Typography';
import Loading from '@app/components/core/Loading';
import {
  InputField,
  InputFieldColor,
} from '@app/components/core/Interaction/InputField/index';
import { BodyText, BodySize } from '@app/components/core/Typography/BodyText';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button/index';

import CREATE_QUERY from '@app/views/login/graphql/queries/AuthCreateQuery';
import { AuthCreateQuery } from '@app/views/login/graphql/queries/types/AuthCreateQuery';

import styles from './LoginForm.module.scss';

interface ErrorMessageProps {
  error: string | undefined;
}

const ErrorMessage = ({ error }: ErrorMessageProps) => {
  return <>{error && <div className={styles.error}>{error}</div>}</>;
};

export const LoginForm: React.FC = () => {
  // const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [errors, setErrors] = useState({} as Record<any, string>);
  const [authCreate, { loading }] = useMutation<AuthCreateQuery>(
    CREATE_QUERY,
    {},
  );

  const buttonTheme = {
    color: ButtonColor.Primary,
    size: ButtonSize.Default,
    width: ButtonWidth.Compact,
  };

  interface FormValues {
    email: string;
    password: string;
  }
  interface FormFunctions {
    resetForm: () => void;
  }

  const initialValues = { email: '', password: '' };

  const checkEmail = (email: string) => {
    if (email.length === 0) {
      setErrors((prev) => ({ ...prev, email: 'Email address is required' }));
    } else if (!validateEmail(email)) {
      setErrors((prev) => ({ ...prev, email: 'Invalid email address' }));
    } else {
      setErrors((prev) => {
        const state = prev;
        delete state.email;
        return { ...state };
      });
    }
  };

  const onFormSubmit = async (
    values: FormValues,
    { resetForm }: FormFunctions,
  ) => {
    if (!loading) {
      try {
        setIsLoading(true);
        const res = await authCreate({ variables: values });
        const token = res.data?.token_auth?.token;
        if (res.data && token) {
          resetForm();
          localStorage.setItem('token', token);
          // history.push('/restaurant');
          // TODO: figured out why this history.push() has looked like it's not working. need to make this works
          window.location.reload();
        }
        setIsLoading(false);
      } catch (e) {
        setErrors((prev) => ({ ...prev, credentials: e.message }));
        setIsLoading(false);
      }
    }
  };

  const validate = (values: FormValues) => {
    checkEmail(values.email);

    return errors;
  };

  return (
    <section className={styles['login-form-wrapper']}>
      <div className={styles['login-form']}>
        <Formik
          initialValues={initialValues}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onFormSubmit}
        >
          {() => {
            return (
              <Form>
                <InputField
                  color={InputFieldColor.Primary}
                  label="Email Address"
                  placeholder="Email Address"
                  name="email"
                  type="text"
                  onBlur={(e) => {
                    checkEmail(e.target.value);
                  }}
                />
                <ErrorMessage error={errors.email} />

                <InputField
                  color={InputFieldColor.Primary}
                  label="Password"
                  placeholder="Password"
                  name="password"
                  type="password"
                />
                <ErrorMessage error={errors.credentials} />

                <Button
                  theme={buttonTheme}
                  type="submit"
                  onClick={() => {
                    setErrors((prev) => {
                      const state = prev;
                      delete state.credentials;
                      return { ...state };
                    });
                  }}
                >
                  {isLoading ? <Loading isLoading={isLoading} /> : 'Sign In'}
                </Button>
              </Form>
            );
          }}
        </Formik>
        <BodyText color={TextColor.Light} size={BodySize.Default}>
          Don’t have an account?{' '}
          <Link to={getAppPathConfig(AppPath.SIGN_UP).uri}>Sign Up</Link>
        </BodyText>
      </div>
    </section>
  );
};

export default LoginForm;
