export enum TextColor {
  Dark = 'dark',
  Light = 'light',
  Grey = 'grey',
  Purple = 'purple',
}

export enum TextWeight {
  Bold = 'bold',
  Normal = 'normal',
}
