import { gql } from '@apollo/client';

/**
 * You can write GraphQL queries as simple as
 * you would write it in GraphiQL!
 *
 * Wrap your query in `gql` to denote GraphQL declarations.
 *
 * More info about GraphQL queries:
 *  https://graphql.org/learn/queries/
 *
 * Don't forget to codegen the types via `npm run gql-types`
 */

export default gql`
  mutation MealRequestQuery(
    $address_data: AddressInput!
    $meal_request_data: MealRequestInput!
    $recipient_data: RecipientInput!
  ) {
    create_meal_request(
      address_data: $address_data
      meal_request_data: $meal_request_data
      recipient_data: $recipient_data
    ) {
      request {
        id
        recipient {
          address {
            id
          }
          name
          recipient_name
          email
          phone_number
        }
      }
    }
  }
`;
