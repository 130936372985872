import React, { useCallback, useState } from 'react';
import { useSidebar, useSidebarProps } from 'hooks/useSidebar';

import { useLazyQuery, useMutation } from '@apollo/client';
import { AddressProps } from '@app/components/core/Address';
import { InvoiceFormKeys } from '@app/views/restaurants/invoice/components/InvoiceForm';
import MealRequestInvoiceForm, {
  MealRequestInvoiceFormData,
} from './MealRequestInvoiceForm';
import { MealRequestInvoiceInfoQuery } from './graphql/queries/types/MealRequestInvoiceInfoQuery';
import QUERY from './graphql/queries/MealRequestInvoiceInfoQuery';

import { MealRequestClaimRequestMutation } from './graphql/mutations/types/MealRequestClaimRequestMutation';
import SUBMIT_CLAIM_QUERY from './graphql/mutations/MealRequestClaimRequestMutation';
import { TermsFormStepKeys } from './MealRequestInvoiceForm/steps/TermsFormStep';

interface FetchedMealRequestInvoiceFormProps {
  address: AddressProps;
  deliveryDate: Date;
  taxRate: number;
  [InvoiceFormKeys.MEAT_MEALS_COUNT]: number;
  [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: number;
}

const prepareMealRequestInvoiceInfoData = (
  data: MealRequestInvoiceInfoQuery,
): FetchedMealRequestInvoiceFormProps | null => {
  if (data == null) {
    return null;
  }

  // Start unwrapping the data and formatting it
  const { request, restaurant } = data;
  if (request == null || restaurant == null) {
    return null;
  }

  const { recipient, delivery_date_of_request: deliveryDate } = request;
  if (recipient == null || deliveryDate == null) {
    return null;
  }
  const address: AddressProps = {
    line1: recipient.address.address_line_1,
    line2: recipient.address.address_line_2,
    city: recipient.address.city,
    state: recipient.address.state,
    postalCode: recipient.address.postal_code,
  };

  const taxRate = restaurant.tax_rate;
  if (taxRate == null) {
    return null;
  }

  return {
    address,
    taxRate,
    deliveryDate: deliveryDate.expected_delivery_at,
    [InvoiceFormKeys.MEAT_MEALS_COUNT]: request.num_meat_meals,
    [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: request.num_vegetarian_meals,
  };
};

interface useMealRequestInvoiceFormControls {
  mealRequestInvoiceForm: React.ReactElement;
  openMealRequestInvoiceForm: () => void;
  closeMealRequestInvoiceForm: () => void;
}

export type useMealRequestInvoiceFormProps = useMealRequestInvoiceFormSpecificProps &
  useSidebarProps;

interface useMealRequestInvoiceFormSpecificProps {
  requestID: string;
  restaurantID: string;
  onSuccess: () => void;
}

/**
 * useMealRequestInvoiceForm is a hook that provides three output objects:
 * @param requestID The meal request ID the invoice form should populate data from
 * @param restaurantID The restaurant making this request
 *
 * @returns mealRequestInvoiceForm: Add this component to the DOM to render the form
 * @returns openMealRequestInvoiceForm: Use this to programmatically open the form
 * @returns closeMealRequestInvoiceForm: Use this to programmatically close the form
 */
export const useMealRequestInvoiceForm = (
  props: useMealRequestInvoiceFormProps,
): useMealRequestInvoiceFormControls => {
  const { onClose, onSuccess, requestID, restaurantID } = props;

  const [open, setOpen] = useState(false);
  const {
    Sidebar,
    openSidebar,
    closeSidebar: closeMealRequestInvoiceForm,
  } = useSidebar({
    ...props,
    onClose: () => {
      if (onClose != null) onClose();
      setOpen(false);
    },
  });

  const doOpenSidebar = useCallback(() => {
    if (open) return;

    setOpen(true);
    openSidebar();
  }, [open, setOpen, openSidebar]);

  const [
    formProps,
    setFormProps,
  ] = useState<FetchedMealRequestInvoiceFormProps | null>(null);
  const onCompleted = useCallback(
    (response) => {
      const preparedData = prepareMealRequestInvoiceInfoData(response);
      if (preparedData == null) {
        return;
      }

      setFormProps(preparedData);
    },
    [setFormProps],
  );
  const [fetchMealRequestData] = useLazyQuery<MealRequestInvoiceInfoQuery>(
    QUERY,
    {
      onCompleted,
      variables: { requestID, restaurantID },
    },
  );

  const openMealRequestInvoiceForm = useCallback(() => {
    if (formProps == null) {
      fetchMealRequestData();
    }

    doOpenSidebar();
  }, [fetchMealRequestData, formProps, doOpenSidebar]);

  const [submitClaim] = useMutation<MealRequestClaimRequestMutation>(
    SUBMIT_CLAIM_QUERY,
    {},
  );

  const useSubmitMealRequestInvoiceForm = (
    data: MealRequestInvoiceFormData,
    calculatedTotal: number,
  ) => {
    submitClaim({
      variables: {
        restaurantID,
        requestID,
        taxPercentage: data.invoiceForm[InvoiceFormKeys.TAX_RATE],
        termsAcknowledgement:
          data.termsForm[TermsFormStepKeys.TERMS_ACKNOWLEGEMENT],
        total: calculatedTotal,
        unitPriceMeat: data.invoiceForm[InvoiceFormKeys.MEAT_MEALS_COST],
        unitPriceVegetarian:
          data.invoiceForm[InvoiceFormKeys.VEGETARIAN_MEALS_COST],
      },
    });
    onSuccess();
  };

  const mealRequestInvoiceForm =
    formProps == null ? (
      <div />
    ) : (
      <Sidebar
        title="Confirm details and submit invoice"
        body={
          <div>
            <MealRequestInvoiceForm
              {...formProps}
              onSubmit={useSubmitMealRequestInvoiceForm}
            />
          </div>
        }
      />
    );

  return {
    mealRequestInvoiceForm,
    openMealRequestInvoiceForm,
    closeMealRequestInvoiceForm,
  };
};

export default useMealRequestInvoiceForm;
