import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { ApolloProvider } from '@apollo/client';
import { client } from '@app/graphql';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// import * as serviceWorker from './serviceWorker';
import App from './App';

Sentry.init({
  dsn:
    'https://444d84a848fd4778bc447d1a5bb54898@o4504267577884672.ingest.sentry.io/4504267578933249',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const EntryPoint: React.FC = () => {
  const onLogout = () => {
    client.clearStore();
  };

  return (
    <ApolloProvider client={client}>
      <App onLogout={onLogout} />
    </ApolloProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <EntryPoint />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
