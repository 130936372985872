import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { BodyText, BodySize } from 'components/core/Typography/BodyText';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button';
import check from '@app/components/core/Interaction/Checkbox/images/check.svg';
import DateRenderer from '@app/components/core/DateRenderer';
import useMyOrderDetailForm from 'views/restaurants/myOrders/hooks/useMyOrderDetailForm';
import {
  NonOutStandingStates,
  ClaimsOfRestaurantProps,
} from '@app/views/restaurants/myOrders/components/basic-component';
import useMyOrderList from '../../hooks/useMyOrderList';
import useMarkClaimDeliveredMutation from '../../hooks/useMarkClaimDeliveredMutation';
import styles from './BasicTable.module.scss';

interface BasicMyOrderRowProps {
  claim: ClaimsOfRestaurantProps;
}

const BasicMyOrderRow: React.FC<BasicMyOrderRowProps> = ({
  claim,
}: BasicMyOrderRowProps) => {
  const { isSidebarOpened, addZindex, removeZindex } = useMyOrderList();
  const { myOrderDetailForm, openMyOrderDetailForm } = useMyOrderDetailForm({
    claimID: claim.id,
    taxRate: claim.taxPercentage,
    onClose: () => removeZindex(),
    outsideClickClosesSidebar: true,
  });

  const buttonTheme = {
    color: ButtonColor.Secondary,
    size: ButtonSize.Small,
    width: ButtonWidth.Compact,
  };

  const [mutate] = useMarkClaimDeliveredMutation();
  const orderRowStyles = [styles['hover-row']];

  if (isSidebarOpened) orderRowStyles.push(styles['unblurred-row']);

  const onMyOrderTableRowClick = () => {
    if (isSidebarOpened) {
      return;
    }

    openMyOrderDetailForm();
    addZindex();
  };

  const onDeliveredButtonClick = () => {
    mutate({ variables: { claimID: claim.id } });
  };

  return (
    <>
      <TableRow
        key={claim.id}
        className={orderRowStyles.join(' ')}
        onClick={onMyOrderTableRowClick}
      >
        {claimStateRenderer(claim.currentState)}
        <TableCell className={styles['data-table-cell']}>
          <BodyText size={BodySize.Default}>
            <DateRenderer date={claim.request.requestTime} />
          </BodyText>
        </TableCell>
        <TableCell className={styles['data-table-cell']}>
          <BodyText size={BodySize.Default}>
            {claim.request.beneficiaryName}
          </BodyText>
        </TableCell>
        <TableCell className={styles['data-table-cell']}>
          <BodyText size={BodySize.Default}>
            {claim.request.totalNumMeals}
          </BodyText>
        </TableCell>
        <TableCell className={styles['data-table-cell']}>
          <BodyText size={BodySize.Default}>
            {/* TODO: need to resolve <div> cannot appear as a child of <tbody>. error */}
            <>
              <div
                className={`price-box ${styles.w200} ${
                  claim.currentState === 'CONFIRMED'
                    ? styles['hide-on-hover']
                    : ''
                }`}
              >
                ${Number(claim.total).toFixed(2)}
              </div>
              <div
                className={`deliver-button ${styles.w200} ${styles.hide} ${styles['deliver-btn-pos']} ${styles['show-on-hover']}`}
              >
                {claim.currentState === 'CONFIRMED' ? (
                  <div className={styles['myorder-row-button']}>
                    <Button
                      type="button"
                      theme={buttonTheme}
                      onClick={onDeliveredButtonClick}
                    >
                      <img src={check} alt="check" />
                      Delivered
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          </BodyText>
        </TableCell>
      </TableRow>

      <div className={styles['myorder-detail-form']}>{myOrderDetailForm}</div>
    </>
  );
};

const claimStateRenderer: React.FC<string> = (state: string) => {
  if (
    state === NonOutStandingStates.COMPLETED ||
    state === NonOutStandingStates.CANCELED
  ) {
    return null;
  }
  return (
    <TableCell className={styles['data-table-cell']}>
      <BodyText size={BodySize.Default}>{state}</BodyText>
    </TableCell>
  );
};
export default BasicMyOrderRow;
