import React from 'react';

/**
 * validateEmail validates if an email input follows the regular email format and if it is, returns true.
 */

const validateEmail = (email: string): boolean => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

/**
 * validateZipcode validates if zipcode has 5 digits and if it is, returns true.
 */

const validateZipcode = (zipcode: string): boolean => {
  return /^\d{5}$/i.test(zipcode);
};

/**
 * validatePasswordMatch validates if password and confirm password are same and if it is, returns true.
 */

const validatePasswordMatch = (pw: string, confirmPw: string): boolean => {
  return pw === confirmPw;
};

/**
 * validateMinMax validates if min value is always less than max value and if it is, returns true.
 */

const validateMinMax = (min: number, max: number): boolean => {
  return min < max;
};

/**
 * validateAlphabet validates if string value has alphabet only and if it is, returns true.
 */

const validateAlphabet = (char: string): boolean => {
  return /^[a-zA-Z]+$/i.test(char);
};

/**
 * formatToPhone converts 10 digits of string to phone number format. ex) (555) 123-4567.
 */

const formatToPhone = (e: React.ChangeEvent<HTMLInputElement>): string => {
  const input = e.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  let tel = '';

  if (input.length > 6) {
    tel = `(${areaCode}) ${middle}-${last}`;
  } else if (input.length > 3) {
    tel = `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    tel = `(${areaCode}`;
  }
  return tel;
};

/**
 * hasEmptyField detect if there is any key having empty value in an object.
 * it is able to exclude the keys you don't want to find out if it's empty or not by adding an array of its key names on second argument
 */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const hasEmptyField = (obj: any, exclude: Array<string>): boolean => {
  let isEmpty = false;
  const x = Object.entries(obj);
  for (let i = 0; i < x.length; i += 1) {
    if (typeof x[i][1] === 'object' && hasEmptyField(x[i][1], exclude)) {
      isEmpty = true;
      break;
    } else if (
      !['boolean', 'number'].includes(typeof x[i][1]) &&
      !x[i][1] &&
      !exclude.includes(x[i][0])
    ) {
      isEmpty = true;
      break;
    }
  }
  return isEmpty;
};

export {
  validateEmail,
  validateZipcode,
  validatePasswordMatch,
  validateMinMax,
  validateAlphabet,
  formatToPhone,
  hasEmptyField,
};
