import React from 'react';
import classnames from 'classnames';
import styles from './ProgressBar.module.scss';
import { TextColor } from '../Typography';
import { HeaderSize, HeaderText } from '../Typography/HeaderText';

export enum ProgressBarColor {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface IProgressBarProps {
  stepIndex: number;
  totalSteps: number;
  color: ProgressBarColor;
}

/**
 * Renders a progress bar.
 */
export const ProgressBar: React.FC<IProgressBarProps> = ({
  stepIndex,
  totalSteps,
  color,
}: IProgressBarProps) => {
  if (stepIndex < 0 || stepIndex >= totalSteps) {
    throw new Error('[ProgressBar] stepIndex out of bounds');
  }

  return (
    <>
      <HeaderText
        size={HeaderSize.XSmall}
        color={
          color === ProgressBarColor.Primary ? TextColor.Dark : TextColor.Light
        }
      >
        Step {stepIndex + 1} of {totalSteps}
      </HeaderText>
      <div className={styles['bar-container']}>
        {[...Array(totalSteps)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Bar key={i} isFocused={i <= stepIndex} color={color} />
        ))}
      </div>
    </>
  );
};

interface IBarProps {
  isFocused: boolean;
  color: ProgressBarColor;
}

const Bar: React.FC<IBarProps> = ({ isFocused, color }: IBarProps) => {
  return (
    <div
      className={classnames([
        styles.bar,
        styles[`${color}-bar`],
        {
          [styles['bar-focused']]: isFocused,
        },
      ])}
    >
      &nbsp;
    </div>
  );
};
