import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { AppPaths, AppPathType, getAppPathConfig } from '@app/paths';
import getAppPathComponent from '@app/paths/components';

import LoginRoute from '@app/paths/LoginRoute';
import PrivateRoute from '@app/paths/PrivateRoute';

import ScrollToTop from '@app/components/core/ScrollToTop';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        {Array.from(AppPaths).map(([path, _]) => {
          const config = getAppPathConfig(path);
          const component = getAppPathComponent(path);
          if (config.pathType === AppPathType.PRIVATE) {
            return (
              <PrivateRoute
                key={config.uri}
                exact
                path={config.uri}
                component={component}
              />
            );
          }
          if (config.pathType === AppPathType.LOGIN) {
            return (
              <LoginRoute
                key={config.uri}
                exact
                path={config.uri}
                component={component}
              />
            );
          }
          return (
            <Route
              key={config.uri}
              exact
              path={config.uri}
              component={component}
            />
          );
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
