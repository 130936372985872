import { gql } from '@apollo/client';

export default gql`
  query MeQuery {
    me {
      id
      restaurant {
        id
      }
    }
  }
`;
