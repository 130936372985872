import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import UserContext from '@app/UserContext';
import { AppPath, getAppPathConfig } from '@app/paths';

interface LoginRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
}

const LoginRoute: React.FC<LoginRouteProps> = ({
  component: Component,
  ...rest
}: LoginRouteProps) => {
  const { user } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        user !== null ? (
          <Redirect to={getAppPathConfig(AppPath.RESTAURANT_HOME).uri} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default LoginRoute;
