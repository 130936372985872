import { TextColor } from 'components/core/Typography';
import { BodySize, BodyText } from 'components/core/Typography/BodyText';
import React from 'react';
import styles from './InvoiceFormErrors.module.scss';

export interface InvoiceFormErrorsProps {
  errors: Map<string, string>;
}

/**
 * InvoiceFormErrors is a stylized input InvoiceFormErrors for managing on/off states
 *
 * @param errors Object map of keys to error messages
 */
const InvoiceFormErrors: React.FC<InvoiceFormErrorsProps> = ({
  errors,
}: InvoiceFormErrorsProps) => {
  if (errors.size === 0) {
    return null;
  }

  const dedupedErrors = new Set(errors.values());
  if (dedupedErrors.size === 0) {
    return null;
  }

  const nodes: React.ReactElement[] = [];
  dedupedErrors.forEach((value) => {
    // TODO: Make this prettier. Will ask garron for a design here
    nodes.push(
      <div key={value} className={styles.error}>
        <BodyText size={BodySize.Small} color={TextColor.Light}>
          {value}
        </BodyText>
      </div>,
    );
  });
  return <>{nodes}</>;
};

export default InvoiceFormErrors;
