import React, { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { AddressProps } from '@app/components/core/Address';
import { useSidebar, useSidebarProps } from '@app/hooks/useSidebar';

import { InvoiceFormKeys } from '@app/views/restaurants/invoice/components/InvoiceForm';

import { MyOrderDetailFormInfoQuery } from './MyOrderDetailForm/graphql/queries/types/MyOrderDetailFormInfoQuery';
import QUERY from './MyOrderDetailForm/graphql/queries/MyOrderDetailFormInfoQuery';
import MyOrderDetailForm from './MyOrderDetailForm';
import styles from './MyorderDetail.module.scss';

interface useMyOrderDetailFormControls {
  myOrderDetailForm: React.ReactElement;
  openMyOrderDetailForm: () => void;
  closeMyOrderDetailForm: () => void;
}

interface FetchedOrderDetailsFormProps {
  claimID: number;
  address: AddressProps;
  orderStatus: string;
  deliveryAt: string;
  [InvoiceFormKeys.MEAT_MEALS_COUNT]: number;
  [InvoiceFormKeys.MEAT_MEALS_COST]: number;
  [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: number;
  [InvoiceFormKeys.VEGETARIAN_MEALS_COST]: number;
}

const prepareOrderDetailsInfoData = (
  data: MyOrderDetailFormInfoQuery,
): FetchedOrderDetailsFormProps | null => {
  if (data == null) {
    return null;
  }

  // Start unwrapping the data and formatting it
  const { claim_details_by_claim_id: claimDetailsByClaimId } = data;
  if (
    claimDetailsByClaimId == null ||
    claimDetailsByClaimId.id == null ||
    claimDetailsByClaimId.current_state == null
  ) {
    return null;
  }
  const { request, current_state: currentState } = claimDetailsByClaimId;

  if (request == null || request.delivery_date_of_request == null) {
    return null;
  }
  const address = {
    line1: request.delivery_date_of_request.address.address_line_1,
    line2: request.delivery_date_of_request.address.address_line_2,
    city: request.delivery_date_of_request.address.city,
    state: request.delivery_date_of_request.address.state,
    postalCode: request.delivery_date_of_request.address.postal_code,
  };
  const deliveryAt =
    request.delivery_date_of_request.completed_at ??
    request.delivery_date_of_request.expected_delivery_at;
  const orderStatus = currentState.state;
  if (orderStatus == null) {
    return null;
  }

  return {
    claimID: Number(claimDetailsByClaimId.id),
    address,
    orderStatus,
    deliveryAt,
    [InvoiceFormKeys.MEAT_MEALS_COUNT]: request.num_meat_meals,
    [InvoiceFormKeys.MEAT_MEALS_COST]: claimDetailsByClaimId.unit_price_meat,
    [InvoiceFormKeys.VEGETARIAN_MEALS_COUNT]: request.num_vegetarian_meals,
    [InvoiceFormKeys.VEGETARIAN_MEALS_COST]:
      claimDetailsByClaimId.unit_price_vegetarian,
  };
};

export type useMyOrderDetailFormProps = useMyOrderDetailFormSpecificProps &
  useSidebarProps;

interface useMyOrderDetailFormSpecificProps {
  taxRate: number;
  claimID: number;
}

/**
 * useMyOrderDetailForm is a hook that provides three output objects:
 *  1) MyOrderDetailForm: Add this component to the DOM to render the form
 *  2) openMyOrderDetailForm: Use this to programmatically open the form
 *  3) closeMyOrderDetailForm: Use this to programmatically close the form
 *
 * TODO(baldwinchang): include the details on the inputs to this hook
 */
export const useMyOrderDetailForm = (
  props: useMyOrderDetailFormProps,
): useMyOrderDetailFormControls => {
  const { onClose, taxRate, claimID } = props;

  const [open, setOpen] = useState(false);

  const {
    Sidebar,
    openSidebar,
    closeSidebar: closeMyOrderDetailForm,
  } = useSidebar({
    ...props,
    onClose: () => {
      if (onClose != null) onClose();
      setOpen(false);
    },
  });

  const doOpenSidebar = useCallback(() => {
    if (open) return;

    setOpen(true);
    openSidebar();
  }, [open, setOpen, openSidebar]);

  const [
    formProps,
    setFormProps,
  ] = useState<FetchedOrderDetailsFormProps | null>(null);
  const onCompleted = useCallback(
    (response) => {
      const preparedData = prepareOrderDetailsInfoData(response);
      if (preparedData == null) {
        return;
      }
      setFormProps(preparedData);
    },
    [setFormProps],
  );
  const [fetchOrderDetailsData] = useLazyQuery<MyOrderDetailFormInfoQuery>(
    QUERY,
    {
      onCompleted,
      variables: { claimID },
    },
  );

  const openMyOrderDetailForm = useCallback(() => {
    if (formProps == null) {
      fetchOrderDetailsData();
    }

    doOpenSidebar();
  }, [fetchOrderDetailsData, formProps, doOpenSidebar]);

  const myOrderDetailForm =
    formProps == null ? (
      <div />
    ) : (
      <Sidebar
        title={
          `${formProps.orderStatus}` === 'DELIVERED' ||
          formProps.orderStatus === 'FULFILLED'
            ? `Order marked as delivered on ${new Date(formProps.deliveryAt)
                .toString()
                .substring(4, 16)}! 🎉`
            : 'Order details and invoice'
        }
        body={
          <div
            className={
              formProps.orderStatus === 'DELIVERED' ||
              formProps.orderStatus === 'FULFILLED'
                ? styles['delivered-bg']
                : ''
            }
          >
            <MyOrderDetailForm {...formProps} taxRate={taxRate} />
          </div>
        }
      />
    );
  return {
    myOrderDetailForm,
    openMyOrderDetailForm,
    closeMyOrderDetailForm,
  };
};

export default useMyOrderDetailForm;
