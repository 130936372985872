import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

export interface LoadingProps {
  isLoading: boolean;
  size?: number;
  color?: string;
}

/**
 * Loading icon to let user know a promise is going on
 *
 * @param isLoading Passing true to show spinner, and pass false to remove it
 * @param size react-spinner parameter for size of icon
 * @param color Color of Loading icon
 */

const Loading: React.FC<LoadingProps> = ({
  isLoading,
  size = 35,
  color = '#F6F7FB',
}: LoadingProps) => {
  return isLoading ? <ClipLoader size={size} color={color} /> : null;
};

Loading.defaultProps = {
  size: 35,
  color: '#F6F7FB',
};

export default Loading;
