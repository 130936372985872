import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { ClaimStateState } from '@app/types/graphql-global-types';
import { BodyText, BodySize } from 'components/core/Typography/BodyText';
import TableCell from '@material-ui/core/TableCell';
import TableHeader from './TableHeader';
import BasicMyOrderRow from './BasicMyOrderRow';

import styles from './BasicTable.module.scss';

export interface BasicTableProps {
  claims: ClaimsOfRestaurantProps[];
  totalAndStateToggle: boolean;
}
export interface ClaimsOfRestaurantProps {
  id: number;
  currentState: ClaimStateState;
  total: number;
  request: {
    id: string;
    requestTime: Date;
    totalNumMeals: number;
    beneficiaryName: string;
  };
  taxPercentage: number;
}

export enum NonOutStandingStates {
  COMPLETED = 'FULFILLED',
  CANCELED = 'CANCELLED',
}

export enum OutStandingStates {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  DELIVERED = 'DELIVERED',
}

const BasicTable: React.FC<BasicTableProps> = ({
  claims,
  totalAndStateToggle,
}: BasicTableProps) => {
  return (
    <>
      <TableContainer>
        <Table className={styles.table} aria-label="order table">
          <TableHeader totalAndStateToggle={totalAndStateToggle} />
          <TableBody>
            {claims.map((claim) => (
              <BasicMyOrderRow key={claim.id} claim={claim} />
            ))}
          </TableBody>
          {/* TO DO: add Total price area refer to sectionTotal.tsx  */}
        </Table>
      </TableContainer>
    </>
  );
};

const claimState: React.FC<string> = (state: string) => {
  if (
    state === NonOutStandingStates.COMPLETED ||
    state === NonOutStandingStates.CANCELED
  ) {
    return null;
  }
  return (
    <TableCell className={styles['data-table-cell']}>
      <BodyText size={BodySize.Default}>{state}</BodyText>
    </TableCell>
  );
};
export default BasicTable;
