import React, { useContext, useEffect, useState } from 'react';
import { TextColor } from 'components/core/Typography';
import HeaderText, { HeaderSize } from 'components/core/Typography/HeaderText';
import { BodyText, BodySize } from 'components/core/Typography/BodyText';
import { MealRequestList } from 'components/MealRequestList';
import { MealRequest } from 'components/MealRequest';
import Logo from 'components/core/Logo';

import { useLazyQuery } from '@apollo/client';
import UserContext from '@app/UserContext';
import AVAILABLE_REQUESTS_QUERY from './graphql/AvailableRequestsQuery';
import { AvailableRequestsQuery } from './graphql/types/AvailableRequestsQuery';
import styles from './MealRequest.module.scss';

export const RestaurantDashboard: React.FC = () => {
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [fetch, { called, data }] = useLazyQuery<AvailableRequestsQuery>(
    AVAILABLE_REQUESTS_QUERY,
    {
      onCompleted: () => setLoading(false),
    },
  );

  useEffect(() => {
    if (!called && !data) {
      setLoading(true);
      fetch();
    }
  }, [called, data, fetch]);

  // TODO: better loading/empty and error states
  if (loading) return null;
  if (user == null || data == null) {
    return <Empty />;
  }

  const mealRequests: MealRequest[] =
    data?.available_requests?.map((request) => {
      const delivery = request.delivery_date_of_request;
      if (delivery == null) {
        throw new Error('Did not receive delivery date');
      }
      return {
        id: request.id,
        numMealsRequested: request.num_meat_meals,
        requestTime: new Date(delivery.expected_delivery_at),
        beneficiaryName: request.recipient.name,
        beneficiaryAddress: request.recipient.address.address_line_1,
        specialRequests: {
          numVegetarian: request.num_vegetarian_meals,
        },
      };
    }) || [];

  if (mealRequests.length === 0) {
    return <Empty />;
  }

  return (
    <>
      <div className={styles.list}>
        <Logo isWithUnderBar />
        <HeaderText size={HeaderSize.Default}>Local Meal Requests</HeaderText>
        <div className={styles.spacer} />
        <MealRequestList
          restaurantId={user?.restaurantId}
          mealRequests={mealRequests}
        />
      </div>
      <div className={styles.divider} />
      <Footer />
    </>
  );
};

const Empty: React.FC = () => {
  return (
    <div className={styles.list}>
      <Logo isWithUnderBar />
      <HeaderText size={HeaderSize.Small}>
        There aren’t any requests in your area right now
      </HeaderText>
    </div>
  );
};

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <HeaderText size={HeaderSize.Small}>Need some help?</HeaderText>
      <BodyText size={BodySize.Small}>You can contact MealForward at:</BodyText>
      <br />
      <HeaderText color={TextColor.Purple} size={HeaderSize.XSmall}>
        <a
          href="mailto:support@mealforward.org"
          className={styles['footer-link']}
        >
          support@mealforward.org
        </a>
      </HeaderText>
    </div>
  );
};

export default RestaurantDashboard;
