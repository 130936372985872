import React from 'react';
import { MealRequest, BasicMealRequest } from 'components/MealRequest';
import VerticalSpacer from '@app/components/core/Layout/VerticalSpacer';

export interface IMealRequestListProps {
  restaurantId: string;
  mealRequests: MealRequest[];
}

export const MealRequestList: React.FC<IMealRequestListProps> = ({
  restaurantId,
  mealRequests,
}: IMealRequestListProps) => {
  return (
    <VerticalSpacer verticalSpacerHeight={24}>
      {mealRequests.map((mr) => (
        <BasicMealRequest
          restaurantId={restaurantId}
          mealRequest={mr}
          key={mr.id}
        />
      ))}
    </VerticalSpacer>
  );
};
