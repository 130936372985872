import React from 'react';
import { Link } from 'react-router-dom';
import { AppPath, getAppPathConfig } from '@app/paths';
import {
  Button,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '@app/components/core/Interaction/Button';
import {
  HeaderText,
  HeaderSize,
} from '@app/components/core/Typography/HeaderText';
import { BodyText, BodySize } from '@app/components/core/Typography/BodyText';
import { TextWeight } from '@app/components/core/Typography';
import styles from './MyOrderNullState.module.scss';

const buttonTheme = {
  color: ButtonColor.Secondary,
  size: ButtonSize.Small,
  width: ButtonWidth.Compact,
};

const MyOrderNullState: React.FC = () => {
  return (
    <div className={styles['background-margin']}>
      <div className={styles['margin-below']}>
        <HeaderText size={HeaderSize.Small}>No orders yet.</HeaderText>
      </div>
      <BodyText size={BodySize.Small} weight={TextWeight.Bold}>
        Claim a meal request to get started
      </BodyText>
      <div className={styles['button-pos']}>
        <Link to={getAppPathConfig(AppPath.RESTAURANT_HOME).uri}>
          <Button type="button" theme={buttonTheme}>
            See Requests.
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default MyOrderNullState;
