import React from 'react';
import Header from '@app/views/landing/components/Header';
import Footer from '@app/views/landing/components/Footer';
import styles from './index.module.scss';

export interface PublicPageLayoutProps {
  children: React.ReactNode;
}

/**
 * PublicPageLayout provides a layout with a header and
 * the footer can be accessible by an unauthenticated users.
 */
export const PublicPageLayout: React.FC<PublicPageLayoutProps> = ({
  children,
}: PublicPageLayoutProps) => {
  return (
    <>
      <Header />
      <div className={styles['public-page-layout-wrapper']}>
        <div className={styles['public-page-layout-container']}>{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default PublicPageLayout;
