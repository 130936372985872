import {
  InputField,
  InputFieldColor,
} from 'components/core/Interaction/InputField';
import { TextColor } from 'components/core/Typography';
import { BodySize, BodyText } from 'components/core/Typography/BodyText';
import React from 'react';
import { InvoiceFormData, InvoiceFormKeys } from '../InvoiceForm';

import styles from './InvoiceFormRow.module.scss';

export function getMealTypeLabel(key: InvoiceFormKeys, count: number): string {
  const components = [];
  switch (key) {
    case InvoiceFormKeys.MEAT_MEALS_COST:
      components.push('meat meal');
      break;
    case InvoiceFormKeys.VEGETARIAN_MEALS_COST:
      components.push('vegetarian meal');
      break;
    default:
      throw new Error('Invalid key');
  }

  // English when plural, include 's'
  if (count !== 1) {
    components.push('s');
  }
  return components.join('');
}

interface InvoiceFormPriceRowProps {
  name: string;
  label?: React.ReactElement | null;
  bolded?: boolean;
  value: string;
}

export const InvoiceFormPriceRow: React.FC<InvoiceFormPriceRowProps> = ({
  name,
  label,
  bolded,
  value,
}: InvoiceFormPriceRowProps) => {
  return (
    <div className={styles['form-row']}>
      <div className={styles['form-row-item']}>
        <BodyText color={TextColor.Light} size={BodySize.Small}>
          <span className={bolded ? styles.bolded : undefined}>
            {label ?? name}
          </span>
        </BodyText>
      </div>
      <div className={`${styles['form-row-item']} ${styles['dollar-value']}`}>
        <InputField
          type="text"
          disabled
          color={InputFieldColor.Secondary}
          value={value}
          name={name}
          width={72}
          justify="right"
        />
      </div>
    </div>
  );
};
InvoiceFormPriceRow.defaultProps = {
  bolded: false,
  label: null,
};

interface InvoiceFormRowProps<T extends InvoiceFormKeys> {
  name: T;
  value: InvoiceFormData[T];
  numberOfMeals: number;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function InvoiceFormRow<T extends InvoiceFormKeys>({
  name,
  value,
  numberOfMeals,
  disabled,
  onChange,
}: InvoiceFormRowProps<T>): React.ReactElement {
  return (
    <div className={styles['form-row']}>
      <div className={styles['form-row-item']}>
        <BodyText color={TextColor.Light} size={BodySize.Small}>
          {numberOfMeals} {getMealTypeLabel(name, numberOfMeals)}
        </BodyText>
      </div>
      <div className={styles['form-row-item']}>
        <BodyText color={TextColor.Light} size={BodySize.Small}>
          x
        </BodyText>
      </div>
      <div className={styles['form-row-item']}>
        <div>
          <div className={styles['no-input-stepper']}>
            <InputField
              type="number"
              color={
                disabled ? InputFieldColor.Secondary : InputFieldColor.Primary
              }
              disabled={disabled}
              value={value.toString()}
              onChange={onChange}
              step={0.01}
              name={name}
              width={48}
              justify="center"
            />
          </div>
        </div>
      </div>
      <div className={styles['form-row-item']}>
        <BodyText color={TextColor.Light} size={BodySize.Small}>
          =
        </BodyText>
      </div>
      <div className={styles['form-row-item']}>
        <InputField
          type="text"
          disabled
          color={InputFieldColor.Secondary}
          value={(value * numberOfMeals).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            useGrouping: true,
          })}
          name={`${InvoiceFormKeys.MEAT_MEALS_COST}_total`}
          width={72}
          justify="right"
        />
      </div>
    </div>
  );
}
InvoiceFormRow.defaultProps = {
  disabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
};

export default InvoiceFormRow;
