// InputField need to be used with <Formik /> and <Form /> of Formik library
// Here's the link of formik tutorial https://formik.org/docs/tutorial
import React, { InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import styles from './InputField.module.scss';
import { Label } from '../Label/index';

export enum InputFieldColor {
  Primary = 'input-color-primary', // white
  Secondary = 'input-color-secondary', // purple
}
export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  color?: InputFieldColor;
  placeholder?: string;
  name: string;
  fullWidth?: boolean;
  width?: number;
  justify?: 'left' | 'center' | 'right';
  label?: React.ReactElement | string;
  labelProps?: Record<string, string>;
  hasError?: boolean | '';
}

export const InputField: React.FC<InputFieldProps> = ({
  type,
  color,
  placeholder,
  fullWidth,
  width,
  justify,
  label,
  labelProps,
  onClick,
  hasError,
  ...props
}) => {
  const [field] = useField(props);

  const inputFieldStyles = [];
  inputFieldStyles.push(styles.root);
  if (fullWidth) inputFieldStyles.push(styles['full-width']);
  if (color === InputFieldColor.Primary)
    inputFieldStyles.push(styles['color-primary']);
  else if (color === InputFieldColor.Secondary)
    inputFieldStyles.push(styles['color-secondary']);

  if (hasError) {
    inputFieldStyles.push(styles['error-border']);
  }
  const classNames = `${inputFieldStyles.join(' ')}`;
  let input = null;

  input = (
    <input
      {...field}
      {...props}
      className={classNames}
      type={type}
      id={field.name}
      placeholder={placeholder}
      style={{ width, textAlign: justify }}
      onClick={onClick}
    />
  );

  if (label) {
    return (
      <Label htmlFor={field.name} {...labelProps} label={label}>
        {input}
      </Label>
    );
  }
  return input;
};

InputField.defaultProps = {
  justify: 'left',
};
