import { useQuery } from '@apollo/client';

import MEALS_DELIVERED_QUERY from './graphql/MealForwardMealsDeliveredMetricQuery';
import { MealForwardMealsDeliveredMetricQuery } from './graphql/types/MealForwardMealsDeliveredMetricQuery';

export interface useMealForwardFundsRaisedMetricProps {
  fallback: string;
}

/**
 * useMealForwardMealsDeliveredMetric is a hook that fetches the
 * number of meals delivered from our backend.
 */
export const useMealForwardMealsDeliveredMetric = ({
  fallback,
}: useMealForwardFundsRaisedMetricProps): string => {
  const { data, loading } = useQuery<MealForwardMealsDeliveredMetricQuery>(
    MEALS_DELIVERED_QUERY,
    {},
  );

  if (loading || !data?.charity_info?.meals_delivered) {
    return fallback;
  }

  return data.charity_info.meals_delivered;
};

export default useMealForwardMealsDeliveredMetric;
