import React from 'react';
import Slider from 'react-slick';

import img1 from '@app/views/landing/images/carousel-img-1.png';
import img2 from '@app/views/landing/images/carousel-img-2.jpg';
import img3 from '@app/views/landing/images/carousel-img-3.jpg';
import img4 from '@app/views/landing/images/carousel-img-4.jpg';
import img5 from '@app/views/landing/images/carousel-img-5.jpg';
import img6 from '@app/views/landing/images/carousel-img-6.jpg';
import img7 from '@app/views/landing/images/carousel-img-7.jpg';

import './carousel.scss';

// TODO: replace photos to new ones and make it non-carousel in mobile display.

export default function MainCarousel(): JSX.Element {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    arrows: false,
  };

  const imgArr = [img1, img2, img3, img4, img5, img6, img7];
  const items = imgArr.map((item) => {
    return (
      <div key={item} style={{ width: 'auto', height: '310px' }}>
        <img src={item} alt={item} />
      </div>
    );
  });
  return (
    <div className="carousel-container">
      <Slider {...settings}>{items}</Slider>
    </div>
  );
}
