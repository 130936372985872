import { gql } from '@apollo/client';

import ClaimDetailsFragment from '@app/views/restaurants/myOrders/graphql/fragments/ClaimDetailsFragment.graphql';

export default gql`
  ${ClaimDetailsFragment}
  mutation MarkDeliveredMutation($claimID: Int!) {
    mark_request_and_claim_delivered(claim_id: $claimID) {
      claim {
        ...ClaimDetailsFragment
      }
    }
  }
`;
