import { useQuery } from '@apollo/client';

import FUNDS_RAISED_QUERY from './graphql/MealForwardFundsRaisedMetricQuery';
import { MealForwardFundsRaisedMetricQuery } from './graphql/types/MealForwardFundsRaisedMetricQuery';

export interface useMealForwardFundsRaisedMetricProps {
  fallback: string;
}

/**
 * useMealForwardFundsRaisedMetricProps is a hook that fetches the
 * funds raised amount from our backend.
 */
export const useMealForwardFundsRaisedMetric = ({
  fallback,
}: useMealForwardFundsRaisedMetricProps): string => {
  const { data, loading } = useQuery<MealForwardFundsRaisedMetricQuery>(
    FUNDS_RAISED_QUERY,
    {},
  );

  if (loading || !data?.charity_info?.dollars_raised) {
    return fallback;
  }

  return data.charity_info.dollars_raised;
};

export default useMealForwardFundsRaisedMetric;
