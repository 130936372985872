import React from 'react';

import styles from './SeventyThirtyLayout.module.scss';

export interface SeventyThirtyLayoutProps {
  leftColumn: React.ReactChild;
  rightColumn: React.ReactChild;

  // Additional options
  showSeparator?: boolean;
  hideLeftColumnOnNarrowViewport?: boolean;
}

/**
 * SeventyThirtyLayout provides a simple 70-30 layout with no additional styling
 */
export const SeventyThirtyLayout: React.FC<SeventyThirtyLayoutProps> = ({
  leftColumn,
  rightColumn,
  showSeparator = true,
  hideLeftColumnOnNarrowViewport = false,
}: SeventyThirtyLayoutProps) => {
  const leftColumnClassName = [styles['left-column']];
  const rightColumnClassName = [styles['right-column']];
  if (showSeparator) {
    rightColumnClassName.push(styles['with-separator']);
  }
  if (hideLeftColumnOnNarrowViewport) {
    leftColumnClassName.push(styles['hide-left-column-on-narrow-viewport']);
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={leftColumnClassName.join(' ')}>
          <div className={styles.content}>{leftColumn}</div>
        </div>
        <div className={rightColumnClassName.join(' ')}>
          <div className={styles.content}>{rightColumn}</div>
        </div>
      </div>
    </div>
  );
};

export default SeventyThirtyLayout;
