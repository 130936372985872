import React from 'react';

import { SeventyThirtyLayout } from '../SeventyThirtyLayout';

import styles from './MFSeventyThirtyLayout.module.scss';

export interface MFSeventyThirtyLayoutProps {
  leftColumn: React.ReactChild;
  rightColumn: React.ReactChild;

  // Additional options
  showSeparator?: boolean;
}

/**
 * MFSeventyThirtyLayout provides a simple 70-30 layout with a background
 * adornment
 */
export const MFSeventyThirtyLayout: React.FC<MFSeventyThirtyLayoutProps> = ({
  leftColumn,
  rightColumn,
  showSeparator,
}: MFSeventyThirtyLayoutProps) => {
  return (
    <div className={styles.root}>
      <SeventyThirtyLayout
        leftColumn={<div className={styles['left-column']}>{leftColumn}</div>}
        rightColumn={
          <div className={styles['right-column']}>{rightColumn}</div>
        }
        showSeparator={showSeparator}
      />
    </div>
  );
};

export default SeventyThirtyLayout;
